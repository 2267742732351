const subareaLabels = {
    'Bloomsbury': 'bloomsbury-subareas-labels',
    'Denmark Street': 'denmark-street-subareas-labels',
    'Seven Dials': 'seven-dials-subareas-labels'
}

const showSubareaNames = (map, caName, show) => {
    if (!subareaLabels[caName]) return;
    map.setLayoutProperty(subareaLabels[caName], 'visibility', show ? "visible": "none")
}

export default showSubareaNames;