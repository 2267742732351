import { initialisePlanningApplications } from "../initialisePlanningApplications/initialisePlanningApplications";
import { makeApiRequest } from "../makeApiRequest/makeApiRequest";

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

async function waitForMapToLoad(map) {
    for (let i=0; i < 100; i++) {
        if (!map.isStyleLoaded()) {
            await timeout(100);
        } else {
            return true;
        }
    }
    return false;
}

export async function addPlanningApplications(map) {
    return new Promise((resolve, reject) => {
        makeApiRequest()
        .then(async (apiData) => {
            const isLoaded = await waitForMapToLoad(map);
            if (isLoaded)  {
                return apiData;
            } else {
                return false
            }
        })
        .then(apiData => {
            if (apiData) {
                initialisePlanningApplications(map, apiData);
                resolve();
            } else {
                reject('failed');
            }
        })
        .catch('failed');
    })
}