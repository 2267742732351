import getNextLocation from './helpers/getNextLocation';
import getPreviousLocation from './helpers/getPreviousLocation';

const newLocation = (documentMap, location, next) => {
    if (next === 'next') {
        return getNextLocation(documentMap, location)
    } else if (next === 'previous') {
        return getPreviousLocation(documentMap, location)
    } else {
        throw new Error('nextLocation was not passed either `next` or `previous` in its input')
    }
}

export default newLocation;
