const fly = (map, [lng, lat, zoom, bearing, pitch]) => {
    
    const bearingToUse = bearing ? bearing : 0;
    const pitchToUse = pitch ? pitch: 0;

    map.flyTo({
        
        center: [lng, lat],
        zoom: zoom,
        bearing: bearingToUse,
        pitch: pitchToUse,

        speed: 1,
        curve: 0.6,
        
        easing: t => Math.sin(t*Math.PI/2),

        essential: true
    })
}

export default fly;