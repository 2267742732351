import React from 'react';
import './TabButton.css';

const TabButton = ({tabName, openTab, handleTabClick}) => {
    const isClicked = openTab===tabName;
    return (
        <button 
            onClick={() => handleTabClick(tabName)} 
            className={isClicked ? 'clicked tabButton' : 'unclicked tabButton'}>
        {tabName}
        </button>
    )
}

export default TabButton;