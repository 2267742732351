import React from 'react';
import DeviceTypeContext from './DeviceTypeContext';

const DeviceType = ({children, deviceType}) => {
    return (
        <DeviceTypeContext.Provider value={deviceType}>
            {children}
        </DeviceTypeContext.Provider>
    )
}

export default DeviceType;