import React from 'react';
import './Description.css';

const caDescriptions = {
    'Bloomsbury': {
        header: `An exceptional conservation area, under exceptional pressures`,
        text: `The Bloomsbury Conservation Area is widely considered to be an internationally significant example of town planning. Its Georgian terraces and beautiful garden squares are world-famous, while it is also home to some of the most iconic buildings in Britain. But its large size and strategic location place it under great threat.`
    },
    'Charlotte Street': {
        header: `A vibrant and dynamic conservation area in the heart of Fitzrovia`,
        text: `The Charlotte Street Conservation Area is home to a thriving local economy of varied independent business coexisting with a substantial local population, a reflection of its Victorian bohemian character.`
    },
    'Denmark Street': {
        header: `A significant conservation area at substantial risk`,
        text: `The Denmark Street Conservation Area is home to some of the oldest buildings in the capital, but due to Crossrail and further development work is under substantial risk of harm.`
    },
    'Fitzroy Square': {
        header: `A stately Georgian square in Fitzrovia`,
        text: `The Fitzroy Square Conservation Area is one of the oldest in Britain, home to a marvellous Georgian square designed by the renowned Adam brothers.`
    },
    'Hanway Street': {
        header: `A forgotten medieval backwater in Central London`,
        text: `Possibly the smallest conservation area in London comprising just two streets, the Hanway Street Conservation Area is an important remnant of London's original medieval street pattern.`
    },
    'Kingsway': {
        header: `Edwardian splendour in the heart of historic London`,
        text: `The Kingsway Conservation Area is home to one of the most impressive Edwardian commercial building projects in Britain.`
    },
    'Seven Dials': {
        header: `An icon of heritage-led regeneration`,
        text: `The Seven Dials Conservation Area, close to being flattened in the sixties, has been saved and magnificently restored by the Seven Dials Trust.`
    },
}

const subareaDescriptions = {
    'Euston': {
        header: `The Gateway of London`,
        text: `Bloomsbury's northern frontier, Euston is home to the Georgian Euston Square and a multitude of listed buildings along Euston Road.`
    },
    'Gordon Square': {
        header: `Georgian residential affluence`,
        text: `The Gordon Square subarea represents in its most well-preserved form Bloomsbury's original residential character.`
    },
    'Knowledge Quarter': {
        header: `An international centre of learning`,
        text: `The Knowledge Quarter subarea is dominated by the twin pillars of the University of London and the British Museum, two world-class academic institutions.`
    },
    'Tottenham Court Road': {
        header: `London's High Street`,
        text: `This subarea is dominated by the bustling Tottenham Court Road, home to a diverse collection of historic architectures.`
    },
    'Bedford Estate': {
        header: `Bloomsbury's Georgian and Victorian character in its most dignified form`,
        text: `Home to Bedford Square, a window into Georgian Bloomsbury, this subarea is also home to Victorian mansion blocks at their most magnificent.`
    },
    'Central Bloomsbury': {
        header: `High Bloomsbury`,
        text: `This subarea encompasses Bloomsbury's main north-south route, lined with Georgian squares, grand Victorian buildings, and dignified Georgian terraces.`
    },
    'Museum Village': {
        header: `Bloomsbury's most historic quarter`,
        text: `Sitting before the shadow of the iconic British Museum, this small area is home to a number of Bloomsbury's most historic buildings from the late Stuart period.`
    },
    'New Oxford Street': {
        header: `A diverse collection of commerical architectures`,
        text: `New Oxford Street is lined with some of the Victorians' best commercial architecture, while also being home to numerous examples of later high quality architectures.`
    },
    'Inns of Court': {
        header: `London's legal quarter`,
        text: `This subarea is dominated by the twin pillars of Gray's Inn and Lincoln's Inn, each extraordinarily well-preserved examples of Georgian and Victorian legal architecture, while also being home to architecture from as early as 1518.`
    },
    'Doughty Estate': {
        header: `Restrained Georgian uniformity`,
        text: `The Doughty Estate has survived remarkably unaltered from its original early Georgian conception, and represents an area of outstanding heritage value.`
    },
    'Holborn': {
        header: `A fine balance of residential, institutional, and commercial uses`,
        text: `The Holborn subarea is home to an eclectic mix of different characters and architectures, clustered around the two focal points of Queen and Red Lion Square.`
    },
    'Foundling Estate': {
        header: `Bloomsbury's greenest quarter`,
        text: `Close to half of the Foundling Estate is open space, providing respite from the dense residential building of the surrounding area.`
    },
    "King's Cross": {
        header: `Bloomsbury's residential quarter`,
        text: `The King's Cross area is densely packed with Georgian terraces and Victorian mansion blocks, with hospitality uses clustered around Cartwright Gardens and Argyle Square.`
    },
    'Calthorpe Estate': {
        header: `Bloomsbury's eastern cousin`,
        text: `East of Bloomsbury's traditional border of Gray's Inn Road, the Calthorpe Estate has survived almost entirely unaltered since its Georgian foundation as a speculative residential estate.`
    },

    //seven dials subareas

    'The Seven Dials': {
        header: `One of the most historically significant streetscapes in London`,
        text: `The Seven Dials represents a carefully curated and maintained mix of residential and commercial uses, radiating from the Seven Dials Monument at its centre.`
    },
    'Great Queen Street': {
        header: `The Centre of English Freemasonry`,
        text: `Dominated by Freemasons' Hall, this medieval east-west route is also home to important terraces of the late Stuart period.`
    },
    'Macklin Street': {
        header: `A narrow and curious streetscape`,
        text: `This tightly packed area with narrow streets and looming buildings retains its Medieval character, while being home to important buildings from throughout the past three centuries.`
    },

    //denmark street subareas

    'Denmark Street': {
        header: `One of the last remaining shreds of Stuart London`,
        text: `This area represents a small island of Stuart London, home to important buildings from the 1680s.`
    },
    'St Giles Church': {
        header: `A small forgotten backwater of medieval alleys and Renaissance architecture`,
        text: `Dominated by St Giles Church and its churchyard, this small area retains its medieval street plan and curious winding alleys.`
    },
    'Centre Point': {
        header: `A nationally significant example of Modern development.`,
        text: `Dominated by the Centre Point tower, this small area represents a nationally significant example of Modern speculative office development.`
    }
}

const designationAreaDescriptions = {
    "Bloomsbury": {
        "1968(1)": `This area forms part of the original designation of the Bloomsbury Conservation Area of 1968.`,
        "1968(2)": `This area forms part of the original designation of the Bloomsbury Conservation Area of 1968.`,
        "1968(3)": `This area forms part of the original designation of the Bloomsbury Conservation Area of 1968.`,
        "1974(2)": `This area was designated as the Strand Conservation Area in 1974, and was included in the Bloomsbury Conservation Area in the most recent boundary review of 2011.`
    },
    "Charlotte Street": {
        "1973(1)": `This area forms the original designation of the Charlotte Street Conservation Area of 1973`,
        "1968(1)": `This area forms part of the original designation of the Bloomsbury Conservation Area of 1968.`
    },
    "Denmark Street": {
        "1984(1)": `This area was originally designated as an extension to the Bloomsbury Conservation Area in 1984.`,
        "1991(1)": `This area was designated as an extension to the Denmark Street Conservation Area in 1998. Due to Crossrail and further development work, it has now lost almost all of its buildings.`
    },
    "Fitzroy Square": {
        "1968(1)": `This area forms part of the original Bloomsbury Conservation Area designation of 1968.`
    },
    "Hanway Street": {
        "1989(1)": `This area was originally designated as an extension to the Bloomsbury Conservation Area in 1989.`
    },
    "Kingsway": {
        "1981(1)": `This area forms the original designation of the Kingsway Conservation area of 1981.`
    },
    "Seven Dials": {
        "1974(1)": `This area forms the original designation of the Seven Dials Conservation Area of 1974.`
    }
}

const Description = ({names}) => {
    if (names.subareaName) {
        return (
            <article id='homeDescription' className='grow padded'>
                <span>{subareaDescriptions[names.subareaName].header}</span>
                <p>{subareaDescriptions[names.subareaName].text}</p>
            </article>
        )
    } else if (names.designationAreaName) {
        let description = '';
        if (designationAreaDescriptions[names.caName][names.designationAreaName]) {
            description = designationAreaDescriptions[names.caName][names.designationAreaName];
        } else {
            description = `This area was designated in ${names.designationAreaName.slice(0,4)} as an extension to the ${names.caName} Conservation Area.`
        }
        return (
            <article id='homeDescription' className='grow padded'>
                <span>Designated in {names.designationAreaName.slice(0,4)}</span>
                <p>{description}</p>
            </article>
        )
    } else {
        const { caName } = names;
        return (
            <main className='grow padded'>
                <article id='homeDescription'>
                    <span>{caDescriptions[caName].header}</span>
                    <p>{caDescriptions[caName].text}</p>
                </article>
            </main>
        )
    }
}

export default Description;