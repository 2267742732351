const getViewport = (viewports, paragraphRef) => {
    if (Object.keys(viewports).includes(paragraphRef)) {
        if (viewports[paragraphRef]["default"]) {
            return viewports[paragraphRef]["default"]
        } else {
            return viewports[paragraphRef]
        }
    } else {
        return viewports["default"];
    }
}

export default getViewport;