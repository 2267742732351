import './SlideUp.css';
import React, { useState } from 'react';

export default ({tabs}) => {
    const allowedTabNames = tabs.map(tab => tab.title);
    const firstOpenTab = allowedTabNames[0];
    const [openTab, setTab] = useState(firstOpenTab);

    const [contentShowing, showContent] = useState(false);

    //click on a tab changes showing to TRUE
    const clickActions = {
        clickTab: (tabName) => {
            setTab(tabName);
            if (!contentShowing) showContent(true);
        },
        closeEverything: () => {
            showContent(false);
        },
        showEverything: () => {
            showContent(true);
        },
        toggleEverything: () => {
            showContent(!contentShowing);
        }
    }

    const tabButtons = tabs.map(tab => {
        const tabName = tab.name;
        const className = openTab===tabName ? 'slideTabSelected' : 'slideTabNotSelected';
        const clickHandler =  () => {clickActions.clickTab(tabName)};
        return (
            <button className={className} onClick={clickHandler}>
                {tabName}
            </button>
        )
    })

    const tabContent = tabs.map(tab => {
        const tabName = tab.name;
        const className = openTab===tabName ? 'slideContentVisible' : 'slideContentInvisible';
        const Component = tab.Component;
        return (
            <div className={className}>
                {Component}
            </div>
        )
    })
    return (
        <div id='slideUp' className='mobile'>
            {
                contentShowing && 
                <button id='hideSlideButton' onClick={clickActions.toggleEverything}>&#9660;</button>
            }
            <div id='tabButtons' className='fullWidthBar'>
                {tabButtons}
            </div>
            <div id='tabContent' className={contentShowing ? 'slideContentVisible' : 'slideContentInvisible'}>
                {tabContent}
            </div>
        </div>
    )
}