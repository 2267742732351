import mapboxgl from 'mapbox-gl';
import style from '../../../../style.json';

export const makeMap = (viewport) => {
    const Map = new mapboxgl.Map({
    container: document.getElementById('mapContainer'),
    style: style,
    center: [viewport.lng, viewport.lat],
    zoom: viewport.zoom
    });
    return Map;
}