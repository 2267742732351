import getSubtitles from '../../../processData/subtitles/getSubtitles';
import getTitles from '../../../processData/titles/getTitles';

const searchSubtitlesByTitleIndex = (document, titleIndex, searchTerm) => {
    const subtitles = getSubtitles(document, {titleIndex});
    let searchResult = [];
    subtitles.forEach((subtitle, subtitleIndex) => {
        if (subtitle.subtitleName.includes(searchTerm)) searchResult.push({titleIndex, subtitleIndex});
    });
    return searchResult;
}

const searchSubtitles = (document, searchTerm) => {
    let searchResult = [];
    const titles = getTitles(document);
    titles.forEach(
        (title, titleIndex) => {
            const searchResultsForTitle = searchSubtitlesByTitleIndex(document, titleIndex, searchTerm);
            searchResult = searchResult.concat(searchResultsForTitle);
        }
    )
    return searchResult;
}

export default searchSubtitles;