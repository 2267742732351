const getDocumentMap = document => {
    const documentMap =  document.content.map(title => {
        return title.subtitles.map(subtitle => {
            return subtitle.paragraphs.length;
        });
    });
    return documentMap;
}

export default getDocumentMap;
