const planningApplicationsInitialState = {
    loaded: false,
    visibility: {
        global: false,
        openApplications: false,
        determinedPastThreeMonths: false
    },
    hoveredApplications: [],
    selectedApplications: [],
    data: null
};

const actionHandlers = {
    'loaded': (oldState, newLoaded) => {
        return {...oldState, loaded: newLoaded};
    }, 
    'visibility': (oldState, newVisibility) => {
        return {...oldState, visibility: {...oldState.visibility, ...newVisibility}};
    },
    'hoveredApplications': (oldState, newHovered) => {
        return {...oldState, hoveredApplications: newHovered};
    },
    'selectedApplications': (oldState, newSelected) => {
        return {...oldState, selectedApplications: newSelected};
    }
};

const planningApplicationsReducer = (state, action) => {
    const requestedActions = Object.keys(action);
    //get new states one at a time, and feed into next action, before returning final state
    let interimPlanningApplicationsState = state;
    //begin loop
    requestedActions.forEach(actionName => {
        const actionValue = action[actionName];
        const actionFunction = actionHandlers[actionName];
        interimPlanningApplicationsState = actionFunction(interimPlanningApplicationsState, actionValue);
    })
    //now return final merged state
    const newPlanningApplicationsState = interimPlanningApplicationsState;
    return newPlanningApplicationsState;
}

export { planningApplicationsInitialState, planningApplicationsReducer };