import React, { useState, useContext } from 'react';
import Document from '../Document/Document';
import Home from '../Home/Home';
import getData from './openDocument/getData';
import DirectoryContext from '../../../Contexts/Directory/DirectoryContext';
import getNames from '../../../Components/Map/directory/getNames';

export default ({setLayers, setViewport}) => {
    //controls which page is open
    const [openPage, setPage] = useState('Home');
    //controls data i.e. appraisal and management documents
    const [data, setData] = useState(null);
    
    const {directory, changeDirectory} = useContext(DirectoryContext);
    const names = getNames(directory);

    const openNewDocument = documentName => {
        const data = getData(documentName, directory);
        setData(data);
        setPage('Document')
        const newDirectory = (directory.length===3 && directory[1]==='subareas') ? directory.slice() : [directory[0]];
        newDirectory.push(documentName.toLowerCase());
        changeDirectory(newDirectory);
    }

    const returnHome = () => {
        setPage('Home');
        setData(null);
        const caName = directory[0];
        changeDirectory([caName]);
    } 

    if (names.caName) {
        if (openPage === 'Document') {
            return (
                <Document
                    data={data}
                    returnHome={returnHome}
                    setLayers={setLayers}
                    setViewport={setViewport}
                />
            )
        } else {
            return (
                <Home
                    names={names}
                    openNewDocument={openNewDocument}
                />
            )
        }
    } else {
        return (
            <span>Click on a conservation area to begin</span>
        )
    }
}