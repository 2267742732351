import React, { useState, useReducer } from 'react';
import mapboxgl from 'mapbox-gl';
//components
import PlanningApplications from '../PlanningApplications/PlanningApplications';
import SlideUp from '../SlideUp/SlideUp';
import Heritage from '../Heritage/Heritage';
import Map from '../Map/Map';
//state
import { getDeviceType } from './stateSetup/deviceType';
import { planningApplicationsInitialState, planningApplicationsReducer } from './stateSetup/planningApplications';
import { viewportInitialState, viewportReducer } from './stateSetup/viewport';
import { layersInitialState, layersReducer } from './stateSetup/layers';
//api key
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

export default () => {
    //decide if mobile version
    const deviceType = getDeviceType();

    //state
    const [viewport, setViewport] = useReducer(viewportReducer, viewportInitialState);
    const [directory, setDirectory] = useState([]);
    const [layers, setLayers] = useReducer(layersReducer, layersInitialState);
    const [planningApplications, setPlanningApplications] = useReducer(planningApplicationsReducer, planningApplicationsInitialState);

    //components
    const heritageComponent = <Heritage
        deviceType={deviceType}
        directoryState={{directory, setDirectory}}
        viewportState={{viewport, setViewport}}
        layersState={{layers, setLayers}}
    />
    const mapComponent = <Map 
        directoryState={{directory, setDirectory}}
        viewportState={{viewport, setViewport}}
        planningApplicationsState={{planningApplications, setPlanningApplications}}
        layersState={{layers, setLayers}}
    />
    const planningApplicationsComponent = <PlanningApplications
        planningApplicationsState={{planningApplications, setPlanningApplications}}
    />
    const slideUpComponent = <SlideUp
        tabs={[{name: 'Heritage', Component: heritageComponent}, {name: 'Applications', Component: planningApplicationsComponent}]}
    />

    if (deviceType === 'mobile') {
        return (
            <>
                {slideUpComponent}
                {mapComponent}
            </>
        )
    } else {
        return (
            <>
                {heritageComponent}
                {mapComponent}
                {planningApplicationsComponent}
            </>
        )
    }
}