const viewportInitialState = {
    value: {
        lng: -0.126,
        lat: 51.521,
        zoom: 13.86,
        bearing: 0.00,
        pitch: 0.00
    },
    fly: false
}

const getNewFlyValue = (oldFly, newFly) => {
    //if fly is already true, immediately set to false
    //this is to prevent 'fly' being called on map when it is already flying
    if (oldFly) {
        return false;
    } else {
        return !!newFly;
    }
}

const viewportReducer = (state, action) => {
    const newFly = getNewFlyValue(state.fly, action.fly);
    const newViewportState = {
        value: action.value,
        fly: newFly
    }
    return newViewportState;
}

export { viewportInitialState, viewportReducer }