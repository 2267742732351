import fly from './fly';

const viewports = {
    'Bloomsbury': [-0.1246, 51.5225, 14.36],
    'Charlotte Street': [-0.1353, 51.5198, 16.16],
    'Denmark Street': [-0.1290, 51.5154, 16.78],
    'Fitzroy Square': [-0.1398, 51.5232, 16.54],
    'Hanway Street': [-0.1317, 51.5171, 17.58],
    'Kingsway': [-0.1201, 51.5172, 16.05],
    'Seven Dials': [-0.1249, 51.5151, 16.06]
}

const flyToCa =(map, caName) => {
    fly(map, viewports[caName])
}

export default flyToCa;

