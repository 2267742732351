const getEnd = (text, upperIndex) => {
    if (upperIndex === text.length-1) {
        return '';
    } else if (text[upperIndex] === '.'){
        return '..';
    } else {
        return '...'
    }
}

export default getEnd;