import flyToInitial from "../fly/flyToInitial"
import flyToCa from "../fly/flyToCa";
import flyToSubarea from "../fly/flyToSubarea";
import flyToDesignationArea from "../fly/flyToDesignationArea";

const setViewport = (map, directory) => {
    switch(directory.length) {
        case 0:
            flyToInitial(map);
            break;
        case 1:
            //fall through
        case 2:
            const caName = directory[0];
            flyToCa(map, caName);
            break;
        case 3:
            switch(directory[1]) {
                case 'subareas':
                    const subareaName = directory[2];
                    flyToSubarea(map, subareaName);
                    break;
                case 'designations':
                    const caName = directory[0];
                    const designationAreaName = directory[2];
                    flyToDesignationArea(map, caName, designationAreaName);
                    break;
                default:
                    flyToCa(map, caName);
                    break;
            }
            break;
        case 4:
            //only happens if directory[2] is a subarea
            const subareaName = directory[2];
            flyToSubarea(map, subareaName);
            break;
        default:
            flyToInitial(map);
            break;
    }
}

export default setViewport;