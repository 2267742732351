const clusterProperties = {
    approved: ['any',
        ['==', ['get', 'decision_type'], 'Granted'],
        ['==', ['get', 'decision_type'], 'No Objection to Emergency Works (CA)'],
        ['==', ['get', 'decision_type'], 'No Objection to Works to Tree(s) in CA',],
        ['==', ['get', 'decision_type'], 'Granted Subject to a Section 106 Legal Agreement'],
        ['==', ['get', 'decision_type'], 'Prior Approval Required - Approval Given'],
    ],
    warning: ['any',
        ['==', ['get', 'decision_type'], 'Refused and Warning of Enforcement Action to be Taken']
    ],
    rejected: ['any',
        ['==', ['get', 'decision_type'], 'Withdrawn Decision'],
        ['==', ['get', 'decision_type'], 'Refused'],
        ['==', ['get', 'decision_type'], 'Prior Approval Required - Approval Refused',]
    ],
    open: ['any',
        ['==', ['get', 'system_status'], 'Registered']
    ]
}

export const addSourcesToMap = (map, applicationData) => {
    const {combinedApplications} = applicationData;
    //add source for clusters
    map.addSource('planningApplicationsClusters', {
        type: 'geojson',
        data: combinedApplications,
        generateId: true,
        cluster: true,
        clusterRadius: 2,
        clusterProperties: {
            'approved': ['+', ['case', clusterProperties.approved, 1, 0]],
            'warning': ['+', ['case', clusterProperties.warning, 1, 0]],
            'rejected': ['+', ['case', clusterProperties.rejected, 1, 0]],
            'open': ['+', ['case', clusterProperties.open, 1, 0]],
        }
    })
    //add source for invisible layer for easy querying
    map.addSource('determinedPastThreeMonths', {
        type: 'geojson',
        data: combinedApplications,
        generateId: true
    })
}
