import showDates from './showDates';

const caNames = [
    'Bloomsbury',
    'Charlotte Street',
    'Denmark Street',
    'Fitzroy Square',
    'Hanway Street',
    'Kingsway',
    'Seven Dials'
]

const resetDates = map => {
    caNames.forEach(caName => showDates(map, caName, false));
}

export default resetDates;