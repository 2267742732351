import showSubareaNames from './showSubareaNames';

const caNames = [
    'Bloomsbury',
    'Charlotte Street',
    'Denmark Street',
    'Fitzroy Square',
    'Hanway Street',
    'Kingsway',
    'Seven Dials'
]

const resetSubareaNames = map => {
    caNames.forEach(caName => {
        showSubareaNames(map, caName, false);
    })
}

export default resetSubareaNames;