import React from 'react';
import Title from './Title.jsx';

const TitleList = ({openDocument, startReading}) => {
    const titleArray = openDocument.content
    .map((title, titleIndex) => {
        return (
            <Title
                openDocument={openDocument}
                startReading={startReading}
                titleIndex={titleIndex}
                key={titleIndex}
            />
        )
    });
    return <nav id='contents' className='padded grow'><ol>{titleArray}</ol></nav>
}

export default TitleList;