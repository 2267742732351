const findLowerIndex = (text, characterIndex, numOfWords) => {
    let lowerIndex = characterIndex;
    if (lowerIndex !== 0) {
        //numOfSpaces corresponds also to numOfWords
        let numOfSpaces = 0;
        while (numOfSpaces < numOfWords && lowerIndex !== 0) {
            //checks if next character is a space. If so we are at the beginning of a word.
            //but skips first space
            lowerIndex --;
            let nextCharacter = text[lowerIndex-1];
            if (nextCharacter === ' ') numOfSpaces ++;
        }
    }
    return lowerIndex;
}

export default findLowerIndex;