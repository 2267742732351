import getSubtitles from '../../../processData/subtitles/getSubtitles';
import getParagraphs from '../../../processData/paragraphs/getParagraphs';
import getTitles from '../../../processData/titles/getTitles';

const textContainsTerm = (text, searchTerm) => {
    const lowerCaseText = text.toLowerCase();
    const lowerCaseSearchTerm = searchTerm.toLowerCase()
    return lowerCaseText.includes(lowerCaseSearchTerm);
}
/*
const searchParagraphsBySubtitle= (document, location, term) => {
    let searchResult = [];
    const paragraphReferences = getParagraphReferences(document, location);
    paragraphReferences.forEach((paragraphReference, paragraphIndex) => {
        //paragraph is a react element object. to check its text, we must check props.children
        const paragraph = getParagraphByRef(document, location, paragraphReference);
        if (searchParagraphForTerm(paragraph, term, location)) {
            const paragraphLocation = {
                titleIndex: location.titleIndex,
                subtitleIndex: location.subtitleIndex,
                paragraphIndex: paragraphIndex
            }
            searchResult.push(paragraphLocation)
        }
    })
    return searchResult;
}
*/

const searchParagraphsBySubtitle = (document, location, searchTerm) => {
    let searchResult = [];
    const paragraphs = getParagraphs(document, location);
    const {titleIndex, subtitleIndex} = location;
    paragraphs.forEach((paragraph, paragraphIndex) => {
        const text = paragraph.text;
        if (textContainsTerm(text, searchTerm)) {
            searchResult.push({titleIndex, subtitleIndex, paragraphIndex})
        }
    })
    return searchResult;
}

/*
const searchParagraphsByTitleIndex = (document, titleIndex, term) => {
    let searchResult = [];
    const subtitleNames = getSubtitleNames(document, {titleIndex: titleIndex});
    subtitleNames.forEach((subtitleName, subtitleIndex) => {
        const subtitleSearchResults = searchParagraphsBySubtitle(document, {titleIndex: titleIndex, subtitleIndex: subtitleIndex}, term);
        searchResult = searchResult.concat(subtitleSearchResults)
    })
    return searchResult;
}
*/

const searchParagraphsByTitleIndex = (document, titleIndex, searchTerm) => {
    let searchResult = [];
    const subtitles = getSubtitles(document, {titleIndex});
    subtitles.forEach((subtitle, subtitleIndex) => {
        const subtitleSearchResults = searchParagraphsBySubtitle(document, {titleIndex, subtitleIndex}, searchTerm);
        searchResult = searchResult.concat(subtitleSearchResults);
    })
    return searchResult;
}

/*
const searchParagraphs = (document, term) => {
    let searchResult = [];
    const titleNumbers = getTitleNumbers(document);
    titleNumbers.forEach((titleNumber, titleIndex) => {
        const titleSearchResult = searchParagraphsByTitleIndex(document, titleIndex, term);
        searchResult = searchResult.concat(titleSearchResult);
    })
    return searchResult;
}
*/

const searchParagraphs = (document, searchTerm) => {
    let searchResult = [];
    const titles = getTitles(document);
    titles.forEach((title, titleIndex) => {
        const titleSearchResult = searchParagraphsByTitleIndex(document, titleIndex, searchTerm);
        searchResult = searchResult.concat(titleSearchResult);
    })
    return searchResult;
}


export default searchParagraphs;

