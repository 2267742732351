import React from 'react';
import moment from 'moment';
import './Header.css';

const decisionTypeClassNames = {
    'Granted': 'approved',
    'No Objection to Emergency Works (CA)': 'approved',
    'No Objection to Works to Tree(s) in CA': 'approved',
    'Granted Subject to a Section 106 Legal Agreement': 'approved',
    'Prior Approval Required - Approval Given': 'approved',
    'Refused and Warning of Enforcement Action to be Taken': 'enforcement',
    'Withdrawn Decision': 'rejected',
    'Refused': 'rejected',
    'Prior Approval Required - Approval Refused': 'rejected'
}

const decisionLevelClassNames = {
    'Delegated': 'low',
    'Withdrawn-Council': 'low',
    'Withdrawn': 'low',
    'Members Briefing': 'medium',
    'Development Control Committee': 'high'
}

const decisionLevelAcronyms = {
    'Delegated': 'D',
    'Withdrawn': 'D',
    'Withdrawn-Council': 'D',
    'Members Briefing': 'MB',
    'Development Control Committee': 'PC'
}

const createOfficerEmail = (officerName) => {
    //escape for edge case
    if (officerName === 'Duty Planner') return 'planning@camden.gov.uk';
    const lowerCaseOfficerName = officerName.toLowerCase();
    const emailAddressNamePart = lowerCaseOfficerName.replace(' ', '.');
    const fullEmailAddress = emailAddressNamePart + '@camden.gov.uk';
    return fullEmailAddress;

}

export default ({details}) => {
    //note: 'decison_level' is a spelling error at API endpoint
    const {
        application_type,
        application_number,
        case_officer, 
        development_address,
        earliest_decision_date, 
        decision_date,
        decision_type,
        decison_level
    } = details;

    //depending on whether application is open or determined
    let justBelowHeading = null;
    if (decision_type !== 'null') {
        const decisionTypeClassName = decisionTypeClassNames[decision_type];
        const decisionLevelClassName = decisionLevelClassNames[decison_level];
        const decisionLevelAcronym = decisionLevelAcronyms[decison_level];
        justBelowHeading = (
            <div className={`decision ${decisionTypeClassName}`}>
                <span className='decisionType'>{decision_type}</span>
                <div className='decisionLevelAcronym'>
                    <span className={decisionLevelClassName}>{decisionLevelAcronym}</span>
                </div>
            </div>
        )
    } else {
        justBelowHeading = (
            <div className='decision openApplication'>
                <span className='decisionType'>Open</span>
            </div>
        )
    }

    const relevantDate = decision_date === 'null' ? earliest_decision_date : decision_date;
    const relevantDateFormatted = moment(relevantDate).format('dddd Do MMMM YYYY');

    const officerEmail = createOfficerEmail(case_officer);
 
    const essentialDetails = (
        <section className='essentialDetails'>
            <span className='applicationNumber'>{application_number}</span>
            <span className='date'>{decision_date==='null' && 'Please comment by '}{relevantDateFormatted}</span>
            <span className = 'developmentAddress'>{development_address}</span>
            <span className='caseOfficer'>{officerEmail}</span>
        </section>
    )

    return (
        <header>
            <h1>{application_type}</h1>
            {justBelowHeading}
            {essentialDetails}
        </header>
    )
}