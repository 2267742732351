import React from 'react';
import './Expanded.css';

export default ({details}) => {
    const {development_description, application_number, comment, decision_date} = details;
    const openCamdenDocuments = () => {
        window.open(`http://camdocs.camden.gov.uk/HPRMWebDrawer/PlanRec?q=recContainer:%22${application_number}%22`)
    }
    const commentOnApplication = () => {
        if (comment) {
            window.open(comment);
        }
    }
    return (
        <div className='expandedInfo'>
            <span className='developmentDescription'>{development_description}</span>
            <div className='fullWidthBar iconBar'>
                <button className='iconButton planningApplicationButton camdenButton' onClick={openCamdenDocuments}>
                    {`Documents`}
                    <img className='icon' src={`/icons/camden.svg`} alt=''/>
                </button>
            </div>
            {
                decision_date==='null' &&
                <div className='fullWidthBar iconBar commentBar'>
                <button className='iconButton planningApplicationButton commentButton' onClick={commentOnApplication}>
                    {`Comment`}
                    <img className='icon' src={`/icons/comment.svg`} alt=''/>
                </button>
            </div>
            }
        </div>
    )
}