const layerNames = {
    'Bloomsbury': 'bloomsbury',
    'Charlotte Street': 'charlotte-street',
    'Denmark Street': 'denmark-street',
    'Fitzroy Square': 'fitzroy-square',
    'Hanway Street': 'hanway-street',
    'Kingsway': 'kingsway',
    'Seven Dials': 'seven-dials'
}

const showCaFill = (map, caName, show) => {
    map.setPaintProperty(layerNames[caName], 'fill-opacity', show ? 1: 0)
}

export default showCaFill;