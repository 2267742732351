const layerNames = {
    'Bloomsbury': 'bloomsbury-designation-labels',
    'Charlotte Street': 'charlotte-street-designation-labels',
    'Denmark Street': 'denmark-street-designation-labels',
    'Fitzroy Square': 'fitzroy-square-designation-labels',
    'Hanway Street': 'hanway-street-designation-labels',
    'Kingsway': 'kingsway-designation-labels',
    'Seven Dials': 'seven-dials-designation-labels'
}

const showDates = (map, caName, show) => {
    if (!layerNames[caName]) return;
    map.setLayoutProperty(layerNames[caName], "visibility", show ? "visible" : "none");
}

export default showDates;