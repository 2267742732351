import showCaNames from './showCaNames';

const caNames = [
    'Bloomsbury',
    'Charlotte Street',
    'Denmark Street',
    'Fitzroy Square',
    'Hanway Street',
    'Kingsway',
    'Seven Dials'
]

const resetCaNames = map => {
    caNames.forEach(caName => {
        showCaNames(map, caName, true);
    })
}

export default resetCaNames;