import React, { useState, useContext, useEffect } from 'react';
import TitleList from './Titles/TitleList.jsx';
import SearchInput from './Search/SearchInput/SearchInput.jsx';
import SearchResults from './Search/SearchResults/SearchResults.jsx';
import search from './Search/helpers/search';
import LocationContext from '../../../../Contexts/Location/LocationContext.jsx';

const ContentsTab = ({openTab, openDocument, changeTab}) => {
    const {setLocation} = useContext(LocationContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState({});
    const updateSearch = (term) => {
        setSearchTerm(term);
        const minSearchLength = 2;
        if (term.length > minSearchLength) {
            setSearchResults(search(openDocument, term));
        } else {
            setSearchResults({});
        }
    }
    const openParagraph = location => {
        changeTab('Read');
        setLocation(location);
    }
    useEffect(() => {
        if (searchTerm.length <= 2) document.getElementById('selectedTitleElement').scrollIntoView();
    });
    return (
        <section className={openTab==='Contents' ? 'openTab' : 'hiddenTab'}>
            <SearchInput
                searchTerm={searchTerm} 
                handleSearchChange={updateSearch}
            />
            { 
                searchTerm.length > 2
                ?
                <SearchResults 
                    searchTerm={searchTerm}
                    searchResults={searchResults}
                    startReading={openParagraph}
                    openDocument={openDocument}
                    openParagraph={openParagraph}
                />
                :
                <TitleList 
                    openDocument={openDocument}
                    startReading={openParagraph}
                />
            }
        </section>
    )
}

export default ContentsTab;