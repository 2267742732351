import resetLayers from '../changeLayers/reset/resetLayers'
import showCaNames from '../changeLayers/names/showCaNames';
import showSubareaNames from '../changeLayers/names/showSubareaNames';
import showAllCaFills from '../changeLayers/areas/showAllCaFills';
import showSubareaFills from '../changeLayers/subareas/showSubareaFills';
import showCaDesignations from '../changeLayers/designations/showCaDesignations';
import resetDesignations from '../changeLayers/designations/resetDesignations';
import resetDates from '../changeLayers/designations/resetDates';
import resetSubareaNames from '../changeLayers/names/resetSubareaNames';
import showDates from '../changeLayers/designations/showDates';
import resetSubareaFills from '../changeLayers/subareas/resetSubareaFills';

const setLayers = (map, directory) => {
    switch (directory.length) {
        case 0:
            //fall through
        case 1:
            resetLayers(map);
            break;
        case 2:
            const caName = directory[0];
            switch(directory[1]) {
                case 'appraisal':
                case 'management':
                    resetLayers(map);
                    break;
                case 'subareas':
                    showCaNames(map, false);
                    showAllCaFills(map, false);
                    resetDates(map);
                    resetDesignations(map);
                    resetSubareaFills(map);
                    showSubareaFills(map, caName, true);
                    showSubareaNames(map, caName, true);
                    break;
                case 'designations':
                    showCaNames(map, false);
                    showAllCaFills(map, false);
                    resetSubareaFills(map);
                    resetSubareaNames(map);
                    showCaDesignations(map, caName, true);
                    showDates(map, caName, true);
                    break;
                default:
                    resetLayers(map);
                    break;
            }
            break;
        default:
            break;
    }
}

export default setLayers;