import getParagraph from '../paragraphs/getParagraph';
import getViewport from './viewport/getViewport';
import getLayers from './layers/getLayers';

const getMapData = (data, location) => {
    const paragraphRef = getParagraph(data.document, location).reference;
    const layers = getLayers(data.layers, paragraphRef);
    const viewport = getViewport(data.viewports, paragraphRef);
    return {viewport, layers};
}

export default getMapData;