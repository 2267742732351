import React from 'react';
import './ImageBanner.css';

const ImageBanner = ({imageData}) => {
    const {url, caption} = imageData;
    if (!url) return;
    return (
        <figure className='banner fullWidth'>
            {caption && <figcaption id='bannerCaption' className='padded fullWidth smallText'>{caption}</figcaption>}
            <img className='bannerImage' src={url} alt={caption}/>
        </figure>
    )
}

export default ImageBanner;
