import React from 'react';

const documentNames = {
    'appraisal': 'Appraisal',
    'management': 'Management'
}

const DocumentButton = ({openNewDocument, documentName}) => {
    const handleClick = () => {
        openNewDocument(documentName)
    }
    return (
        <button className='iconButton' onClick={handleClick}>
            <img className='icon' src={`/icons/${documentName}.svg`} alt=''/>
            <span className='iconLabel'>{documentNames[documentName]}</span>
        </button>
    )
}

export default DocumentButton;