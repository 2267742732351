import React from 'react';
import ParagraphResult from './ParagraphResult/ParagraphResult.jsx';
import getSubtitle from '../../../processData/subtitles/getSubtitle';
import './SearchResults.css';

const SearchResults = ({openDocument, searchResults, searchTerm, startReading}) => {
    let {subtitles, paragraphs} = searchResults;
    subtitles = subtitles.map(location => <li>{getSubtitle(openDocument, location).subtitleName}</li>)
    paragraphs = paragraphs.map((location, resultIndex) => {
     return (
        <ParagraphResult 
            key={resultIndex}
            openDocument={openDocument} 
            location={location} 
            searchTerm={searchTerm}
            startReading={startReading}
        />
     )
    })

    if (subtitles.length || paragraphs.length) {
        return (
            <section className='searchResults padded grow'>
                { subtitles.length > 0 && 
                    <section>
                        <h1 className='resultsHead'>Section Results ({subtitles.length})</h1>
                        <div className='resultsList'>
                            <ul id='subtitleResults'>{subtitles}</ul>
                        </div>
                    </section>
                }
                { paragraphs.length > 0 &&
                    <section>
                        <h1 className='resultsHead'>Paragraph Results ({paragraphs.length})</h1>
                        <div className='paragraphResults'>
                            <ul>{paragraphs}</ul>
                        </div>
                    </section>
                }
            </section>
        )
    } else {
        return <span>No results for that search.</span>
    } 
};

export default SearchResults;