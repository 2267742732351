import React, { useContext } from 'react';
import DirectoryContext from '../../../../Contexts/Directory/DirectoryContext';


const DesignationButton = () => {
    const {directory, changeDirectory} = useContext(DirectoryContext);
    const designationsShowing = directory[1]==='designations';
    const handleClick = () => {
        const newDirectory = designationsShowing ? [directory[0]] : [directory[0], 'designations'];
        changeDirectory(newDirectory);
    }
    const buttonText = designationsShowing ? 'Hide' : 'Designations';
    const icon = <img className='icon' src='/icons/designations.svg' alt=''/>
    return (
        <button className='iconButton' onClick={handleClick}>
            {icon}
            <span className='iconLabel'>{buttonText}</span>
        </button>
    )
}

export default DesignationButton;