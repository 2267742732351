import './PlanningApplications.css';
import React from 'react';
import Header from './Header/Header';
import Expanded from './Expanded/Expanded';

export default ({planningApplicationsState}) => {
    const {planningApplications, setPlanningApplications} = planningApplicationsState;
    const {visibility, hoveredApplications, selectedApplications} = planningApplications;

    const somethingIsHovered = hoveredApplications.length;
    const somethingIsSelected = selectedApplications.length;

    //escape for Camden server error
    if (planningApplications === 'failed') {
        return (
            <aside id='planningApplications' className='loading shadow'>
                <h1>Camden Error. Try Refreshing.</h1>
            </aside>
        )
    }

    //escape for still loading
    if (!planningApplications.loaded) {
        return (
            <aside id='planningApplications' className='loading shadow'>
                <h1>Waiting for Camden...</h1>
                <img className='spinner' src={`/icons/spinner.svg`} alt=''/>
            </aside>
        )
    }
    //escape for not showing
    if (!visibility.global) {
        return (
            <aside id='planningApplications' className='awaitingClick shadow' onClick={() => setPlanningApplications({visibility: {global: true, openApplications: true, determinedPastThreeMonths: true}})}>
                <h1>Show Applications</h1>
            </aside>
        )
    }

    //normal use case

    let content = null;

    if (!somethingIsSelected && !somethingIsHovered) {
        const openApplicationsIsChecked = visibility.openApplications;
        const determinedApplicationsIsChecked = visibility.determinedPastThreeMonths;
        content = (
            <>
                <h1 className='hoverAndClick'>Hover and click to view details</h1>
                <div className='checkbox'>
                    <label htmlFor='openApplications'>Open Applications</label>
                    <input 
                        type='checkbox' 
                        name='openApplications' 
                        id='openApplications'
                        defaultChecked={openApplicationsIsChecked} 
                        onChange={e => {setPlanningApplications({visibility: {openApplications: e.target.checked}})}}
                    />
                </div>
                <div className='checkbox'>
                    <label htmlFor='determinedApplications'>Recently Determined Applications</label>
                    <input
                        type='checkbox' 
                        name='determinedApplications'
                        id='determinedApplications'
                        defaultChecked={determinedApplicationsIsChecked}
                        onChange={e => {setPlanningApplications({visibility: {determinedPastThreeMonths: e.target.checked}})}}
                    /> 
                </div>
                <div className='fullWidthBar hideApplications iconBar'>
                    <button className='iconButton hideButton' onClick={() => {setPlanningApplications({visibility: {global: false}})}}>
                        {'Hide Applications'}
                        <img className='icon' src={`/icons/eye.svg`} alt=''/>
                    </button>
                </div>
            </>
        )
    } else if (!somethingIsSelected && somethingIsHovered) {
        const hoveredApplicationDetails = hoveredApplications.map(application => application.properties);
        const applicationsToRender = hoveredApplicationDetails.map((details, index) => <li key={index}><Header details={details}/></li>)
        content = (
                <ul>
                    {applicationsToRender}
                </ul>
        )
    } else if (somethingIsSelected) {
        const selectedApplicationDetails = selectedApplications.map(application => application.properties);
        const applicationsToRender = selectedApplicationDetails.map((details, index) => {
            return (
                <li key={index}>
                    <Header details={details}/>
                    <Expanded details={details}/>
                </li>
            )
        })
        content = (
            <ul>
                {applicationsToRender}
            </ul>
        )
    }

    return (
        <aside id='planningApplications' className='shadow'>
            {content}
        </aside>
    )
}