import findLowerIndex from './findLowerIndex';
import findUpperIndex from './findUpperIndex';
import getEnd from './getEnd';
import getStart from './getStart';
import highlightText from './highlightText';

const makeTextSnippet = (text, characterIndex, numOfWords, searchTerm) => {
    const upperIndex = findUpperIndex(text, characterIndex, numOfWords);
    const lowerIndex = findLowerIndex(text, characterIndex, numOfWords);
    let textSnippet = text.slice(lowerIndex, upperIndex + 1);
    const end = getEnd(text, upperIndex);
    const start = getStart(text, lowerIndex);
    textSnippet = start + textSnippet + end;
    textSnippet = highlightText(textSnippet, searchTerm);

    return textSnippet;
    
}

export default makeTextSnippet;
  