import showCaDesignations from './showCaDesignations';

const caNames = [
    'Bloomsbury',
    'Charlotte Street',
    'Denmark Street',
    'Fitzroy Square',
    'Hanway Street',
    'Kingsway',
    'Seven Dials'
]

const resetDesignations = (map) => {
    caNames.forEach(caName => {
        showCaDesignations(map, caName, false);
    })
}

export default resetDesignations;