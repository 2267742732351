import React from 'react';
import DynamicParagraphs from './DynamicParagraphs/DynamicParagraphs.jsx';
import NavButton from './NavButton/NavButton';

const ReadTab = ({data, changeParagraph, openTab}) => {
    return (
        <section className={openTab==='Read' ? 'openTab' : 'hiddenTab'}>
            <DynamicParagraphs 
                openDocument={data.document}
            />
            <nav id='paragraphsNav' className='fullWidthNav iconBar'>
                <NavButton next='previous' handleClick={changeParagraph} openDocument={data.document} />
                <NavButton next='next' handleClick={changeParagraph} openDocument={data.document} />
            </nav>
        </section>
    ) 
}

export default ReadTab;