const getViewport = (map) => {
    return ({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2),
        bearing: map.getBearing().toFixed(2),
        pitch: map.getPitch().toFixed(2)
    })
}

export const moveHandler = (map, event, {setViewport}) => {
    const newViewport = getViewport(map);
    setViewport({
        value: newViewport
    });
}