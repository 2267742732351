import React from 'react';
import TabButton from '../../Buttons/TabButton/TabButton.jsx';
import HomeButton from '../../Buttons/HomeButton/HomeButton.jsx';
import './DocumentNavBar.css';

const DocumentNavBar = ({openTab, handleTabClick, returnHome}) => {
    return (
        <nav id='documentNavBar' className='fullWidthBar fullWidth'>
            <HomeButton handleClick={returnHome} />
            <TabButton tabName='Contents' openTab={openTab} handleTabClick={handleTabClick}/>
            <TabButton tabName='Read' openTab={openTab} handleTabClick={handleTabClick}/>
        </nav>
    );
}

export default DocumentNavBar;