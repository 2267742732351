import React, { useContext } from 'react';
import Paragraph from '../Paragraph/Paragraph.jsx';
import LocationContext from '../../../../../Contexts/Location/LocationContext';
import getParagraphs from '../../processData/paragraphs/getParagraphs';

const DynamicParagraphs = ({openDocument}) => {
    const {location} = useContext(LocationContext);
    const {titleIndex, subtitleIndex, paragraphIndex} = location;
    const paragraphArray = getParagraphs(openDocument, {titleIndex, subtitleIndex})
    .map(paragraph => <Paragraph openDocument={openDocument} paragraph={paragraph} />)
    return (
        <article id='paragraphs' className='padded grow'>
            {paragraphArray[paragraphIndex]}
        </article>
    )   
}

export default DynamicParagraphs;

//the paragraphs id is necessary for the NextButton to scroll back to the top of the paragraphs, but not for styling!