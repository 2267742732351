import convertName from "../../../../../utils/convertName";

const getImageFolderPath = (directory) => {
    if (!directory.length) return;
    const caName = directory[0];
    const caNameToUse = convertName(caName);
    switch (directory.length) {
        case 0:
            throw new Error('getImageFolderPath was invoked with empty directory.');
        case 1:
            //fall through
        case 2:
            switch(directory[1]) {
                case 'management':
                case 'appraisal':
                case 'designations':
                    return `./bannerPhotos/defaultRes/${caNameToUse}/`;
                case 'subareas':
                    return `./bannerPhotos/defaultRes/${caNameToUse}/`;
                default:
                    return `./bannerPhotos/defaultRes/${caNameToUse}/`;
            }
        case 3:
            if (directory[1]==='designations') return `./bannerPhotos/defaultRes/${caNameToUse}/`;
            //fall through
        case 4:
            const subareaName = directory[2];
            const subareaNameToUse = convertName(subareaName);
            return `./bannerPhotos/defaultRes/${caNameToUse}/subareas/${subareaNameToUse}/`;
        default:
            console.log("directory:")
            console.log(directory)
            throw new Error('getImageFolderPath was invoked with an improper directory. Directory shown in console.')
    } 
}

export default getImageFolderPath;