import * as d3 from 'd3';
import mapboxgl from 'mapbox-gl';

const colorScale = d3.scaleOrdinal()
.domain(["approved", "warning", "rejected", "open"])
.range(['#339933', '#000000', '#cc0000', '#fcba03']);

const createClusterMarker = (props) => {
  const div = document.createElement('div');

  //decide type of application
  const data = [
      {type: 'approved', count: props.approved},
      {type: 'warning', count: props.warning},
      {type: 'rejected', count: props.rejected},
      {type: 'open', count: props.open}
  ];

  //settings
  const thickness = 3;
  const scale = d3.scaleLinear()
    .domain([1, 20])
    .range([150, 400]);
  const radius = Math.sqrt(scale(props.point_count));
  const circleRadius = radius - thickness;

  //create svg
  const svg = d3.select(div)
    .append('svg')
    .attr('class', 'pie')
    .attr('width', radius * 2)
    .attr('height', radius * 2);
  
  // create a group to hold our arc paths and center
  const g = svg.append('g')
    .attr('transform', `translate(${radius}, ${radius})`);

  // create an arc using the radius above
  const arc = d3.arc()
    .innerRadius(radius - thickness)
    .outerRadius(radius);
  
  // create the pie for the donut
  const pie = d3.pie()
    .value(d => d.count)
    .sort(null);
  
  // using the pie and the arc, create our path based on the data
  g.selectAll('path')
    .data(pie(data.sort((x, y) => d3.ascending(y.count, x.count))))
    .enter()
    .append('path')
    .attr('d', arc)
    .attr('fill', (d) => colorScale(d.data.type))
  
  // create the center circle
  g.append('circle')
    .attr('r', circleRadius)
    .attr('fill', 'rgba(0, 0, 0, 0.7)')
    .attr('class', 'center-circle')
  
  // create the text
  g.append("text")
      .attr("class", "total")
      .text(props.point_count_abbreviated)
      .attr('text-anchor', 'middle')
      .attr('dy', 5)
      .attr('fill', 'white')
 
  return div;
}

let markersPreviouslyOnScreen = {};

export const updateClusterMarkers = (map) => {
  let markersNowOnScreen = {};

  const features = map.querySourceFeatures('planningApplicationsClusters');
  features.forEach(feature => {
      const props = feature.properties;
      const coordinates = feature.geometry.coordinates;

      if (!props.cluster) return;

      let marker;
      const id = props.cluster_id;

      const alreadyRendered = !!markersPreviouslyOnScreen[id];
      
      if (!alreadyRendered) {
          const el = createClusterMarker(props);
          marker = new mapboxgl.Marker({ element: el });
          marker.setLngLat(coordinates);
          marker.addTo(map);
          markersNowOnScreen[id] = marker;
      } else {
          //just copy and paste old marker
          markersNowOnScreen[id] = markersPreviouslyOnScreen[id];
      }
  })

  //check to remove old markers from screen

  const allVisibleMarkerIDs = Object.keys(markersNowOnScreen);
  const allPreviouslyVisibleMarkersIDs = Object.keys(markersPreviouslyOnScreen);

  allPreviouslyVisibleMarkersIDs.forEach(id => {
      const noLongerVisible = !allVisibleMarkerIDs.includes(id);
      if (noLongerVisible) {
          const oldMarker = markersPreviouslyOnScreen[id];
          oldMarker.remove()
      }
  })

  //for next call of function
  markersPreviouslyOnScreen = markersNowOnScreen;
  return markersNowOnScreen;
}