const getNextState = (documentOrder, {titleIndex, subtitleIndex, paragraphIndex}) => {
    const maxParagraphIndex = documentOrder[titleIndex][subtitleIndex]-1;
    if (paragraphIndex < maxParagraphIndex) {
        return ({
            titleIndex: titleIndex,
            paragraphIndex: paragraphIndex + 1,
            subtitleIndex: subtitleIndex,
            elementIndex: 0
        })
    } else {
        const maxSubtitleIndex = documentOrder[titleIndex].length-1;  
        if (subtitleIndex < maxSubtitleIndex) {
            return ({
                titleIndex: titleIndex,
                subtitleIndex: subtitleIndex + 1,
                paragraphIndex: 0,
                elementIndex: 0
            })
        } else {
            return ({
                titleIndex: titleIndex + 1,
                subtitleIndex: 0,
                paragraphIndex: 0,
                elementIndex: 0
            })
        }
    }     
}

export default getNextState;