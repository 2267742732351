import getClickedFeatures from './getClickedFeatures';

const directoryActionsByLength = {
    0: (map, directory, clickEvent) => {
        const conservationAreaNames = getClickedFeatures(map, clickEvent, 'conservationArea');
        return conservationAreaNames;
    },
    1: (map, directory, clickEvent) => {
        const conservationAreaNames = getClickedFeatures(map, clickEvent, 'conservationArea');
        return conservationAreaNames;
    },
    2: (map, directory, clickEvent) => {
        const entryToConsider = directory[1];
        
        //do nothing if in an uninteractive entry
        const uninteractiveEntries = ['appraisal', 'management'];
        if (uninteractiveEntries.includes(entryToConsider)) return directory;

        const caName = directory[0];
        const layerTypes = {
            'subareas': 'subarea',
            'designations': 'designationArea'
        }
        const requiredInfoCallback = {
            'subareas': subarea => subarea.Name,
            'designations': designationArea => designationArea.areaId
        }
        const clickedFeatures = getClickedFeatures(map, clickEvent, layerTypes[entryToConsider]);
        const clickedFeature = clickedFeatures[0];
        if (clickedFeature) {
            const requiredInfo = requiredInfoCallback[entryToConsider](clickedFeature);
            return [caName, entryToConsider, requiredInfo]
        } else {
            return directory
        }
    },
    3: (map, directory, clickEvent) => {
        const caName = directory[0];
        const entryToConsider = directory[1];
        const layerTypes = {
            'subareas': 'subarea',
            'designations': 'designationArea'
        }
        const requiredInfoCallback = {
            'subareas': subarea => subarea.Name,
            'designations': designationArea => designationArea.areaId
        }
        const clickedFeatures = getClickedFeatures(map, clickEvent, layerTypes[entryToConsider]);
        const clickedFeature = clickedFeatures[0];
        if (clickedFeature) {
            const requiredInfo = requiredInfoCallback[entryToConsider](clickedFeature);
            return [caName, entryToConsider, requiredInfo]
        } else {
            return directory
        }
    },
    4: (map, directory, clickEvent) => {
        return directory;
    }
}

const handleMapClick = (map, directory, clickEvent) => {
    //directory actions
    const directoryLength = directory.length;
    const requiredDirectoryCallback = directoryActionsByLength[directoryLength];
    const directoryToReturn = requiredDirectoryCallback(map, directory, clickEvent);
    //planningApplicationActions
    const planningApplicationToReturn = getClickedFeatures(map, clickEvent, 'planningApplications')
    return {
        directory: directoryToReturn,
        planningApplication: planningApplicationToReturn
    }
}

export default handleMapClick;