const layersInitialState = {
    all: [],
    showing: []
};

const layersReducer = (state, action) => {
    const newLayersState = {
        ...state,
        ...action
    }
    return newLayersState;
}

export { layersInitialState, layersReducer };