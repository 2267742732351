import getClickedFeatures from "../helpers/getClickedFeatures";

const makeFocused = (map, feature, makeFocused) => {
    map.setFeatureState(feature, {focused: makeFocused})
}

export const mousemoveHandler = (map, event, {planningApplications, setPlanningApplications}) => {
    if (!(map.isStyleLoaded() && planningApplications.loaded)) return;
    const oldHoveredPlanningApplications = planningApplications.hoveredApplications;
    const currentlySelectedPlanningApplications = planningApplications.selectedApplications;
    const newHoveredPlanningApplications = getClickedFeatures(map, event, 'planningApplications');

    if (newHoveredPlanningApplications.length > 0) {
        map.getCanvas().style.cursor = 'pointer';
    } else {
        map.getCanvas().style.cursor = '';
    }

    oldHoveredPlanningApplications.forEach(feature => makeFocused(map, feature, false));
    newHoveredPlanningApplications.forEach(feature => makeFocused(map, feature, true));
    currentlySelectedPlanningApplications.forEach(feature => makeFocused(map, feature, true));

    setPlanningApplications({hoveredApplications: newHoveredPlanningApplications})    
}