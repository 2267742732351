import React from 'react';
import DirectoryContext from './DirectoryContext';

class Directory extends React.Component {
    shouldComponentUpdate(nextProps) {
        //note: this prevents the entire sidebar and all its children from rerendering every time the map changes
        //removing it will cause everything to rerender multiple times every second!
        const currentDirectory = this.props.directory;
        const nextDirectory = nextProps.directory;
        return  JSON.stringify(currentDirectory) !== JSON.stringify(nextDirectory);
    }
    render() {
        const {directory, changeDirectory, children} = this.props;
        return (
            <DirectoryContext.Provider value={{directory, changeDirectory}}>
                {children}
            </DirectoryContext.Provider>
        )
    }
}

export default Directory;