import copyText from './copyText';
import getParagraph from '../../processData/paragraphs/getParagraph';

const copyParagraph = (data, location) => {
    const {text, reference} = getParagraph(data.document, location);
    const {caName, documentName} = data.document.meta;
    let documentNameToUse = documentName;
    if (documentName === 'Management') documentNameToUse = 'Management Plan';
    const textToCopy = `Paragraph ${reference} of the ${caName} Conservation Area ${documentNameToUse} states: '${text}'`;
    copyText(textToCopy);
}

export default copyParagraph;