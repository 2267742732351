import React, { useContext } from 'react';
import isEndOfDocument from './helpers/isEndOfDocument';
import isStartOfDocument from './helpers/isStartOfDocument';
import LocationContext from '../../../../../Contexts/Location/LocationContext';

const NavButton = ({handleClick, next, openDocument}) => {
    const {location} = useContext(LocationContext);
    const onClick = () => {
        handleClick(next)
    }
    
    if (next==='next') {
        if (!isEndOfDocument(location, openDocument)) {
            return <button onClick={onClick} className='iconButton centred'>{`Next`}</button>
        } else {
            return <button className='iconButton centred'>{`Next`}</button>
        }
    } else if (next==='previous') {
        if (!isStartOfDocument(location, openDocument)) {
            return <button onClick={onClick} className='iconButton centred'>{`Previous`}</button>
        } else {
            return <button className='iconButton centred'>{`Previous`}</button>
        }  
    } else {
        throw new Error(`NavButton must be passed either 'next' or 'previous' as a prop.`);
    };
};

export default NavButton;