import React, { useContext }from 'react';
import LocationContext from '../../../../Contexts/Location/LocationContext';
import copyParagraph from './helpers/copyParagraph';
import './CopyButton.css';

const CopyButton = ({data}) => {
    const {location} = useContext(LocationContext);
    const handleClick = () => {
        copyParagraph(data, location);
    }
    return <button id='copyButton' onClick={handleClick}>Copy</button>
}

export default CopyButton;