import React from 'react';
import './SearchInput.css'

const SearchInput = ({searchTerm, handleSearchChange}) => {
    const handleChange = (event) => {
        const searchTerm = event.target.value;
        handleSearchChange(searchTerm)
    }
    return (
        <input
        autoComplete='off'
        spellCheck='false'
        id='searchDocumentInput'
        className='padded fullWidth'
        type='text' 
        value={searchTerm} 
        onChange={handleChange} 
        placeholder='Search'
        ></input>
    );
};

export default SearchInput;