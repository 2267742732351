import { updateDirectory } from "./helpers/updateDirectory";
import { updateLayers } from "./helpers/updateLayers";
import { updateViewport } from "./helpers/updateViewport";
import { updateVisibility } from "./helpers/updateVisibility";

export const updateMap = (map, {prevProps, newProps}) => {
    //update planning apps
    const prevVisibility = prevProps.planningApplicationsState.planningApplications.visibility;
    const newVisibility = newProps.planningApplicationsState.planningApplications.visibility;
    updateVisibility(map, prevVisibility, newVisibility);

    //update directory
    const prevDirectory = prevProps.directoryState.directory;
    const newDirectory = newProps.directoryState.directory;
    updateDirectory(map, prevDirectory, newDirectory);

    //update viewport
    const newViewport = newProps.viewportState.viewport;
    updateViewport(map, newViewport);

    //layers
    const layers = newProps.layersState.layers;
    updateLayers(map, layers);
}