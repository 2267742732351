import getFolderPath from './getFolderPath';

const getData = (documentName, directory) => {
    const folderPath = getFolderPath(directory, documentName);
    const document = require(`../../../../data/conservationAreas/${folderPath}/${documentName}.json`);
    const viewports = require(`../../../../data/conservationAreas/${folderPath}/viewports.json`);
    const layers = require(`../../../../data/conservationAreas/${folderPath}/layers.json`);
    const images = require(`../../../../data/conservationAreas/${folderPath}/images.json`);

    return {document, viewports, layers, images}
}

export default getData;