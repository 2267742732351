import React, { useContext } from 'react';
import DirectoryContext from '../../../Contexts/Directory/DirectoryContext';
import ImageBanner from '../ImageBanner/ImageBanner.jsx';
import HomeHeader from './HomeHeader/HomeHeader';
import Description from './Description/Description.jsx';
import getImageFolderPath from '../Document/processData/images/getImageFolderPath';
import './Home.css';
import DesignationButton from '../Buttons/DesignationButton/DesignationButton';
import SubareaButton from '../Buttons/SubareaButton/SubareaButton';
import DeviceTypeContext from '../../../Contexts/DeviceType/DeviceTypeContext';
import DocumentButton from '../Buttons/DocumentButton/DocumentButton';

const descriptions = {
    'Bloomsbury': 'Cartwright Gardens',
    'Charlotte Street': 'Colville Place',
    'Denmark Street': `St Giles' Church`,
    'Fitzroy Square': 'Fitzroy Square',
    'Hanway Street': 'Hanway Place',
    'Kingsway': 'The Kingsway',
    'Seven Dials': 'The Seven Dials'
}

export default ({names, openNewDocument}) => {
    const {directory} = useContext(DirectoryContext);

    const buttons = {
        'appraisal': <DocumentButton documentName='appraisal' openNewDocument={openNewDocument} key='appraisal'/>,
        'management': <DocumentButton documentName='management' openNewDocument={openNewDocument} key='management'/>,
        'subareas': <SubareaButton key='subareas'/>,
        'designations': <DesignationButton key='designations'/>
    }

    const deviceType = useContext(DeviceTypeContext);
    const isSubarea = !!names.subareaName;

    if (deviceType !== 'mobile') {
        return (
            <section id='home'>
                <ImageBanner 
                    imageData={{url: getImageFolderPath(directory)+'default.JPG', caption: descriptions[names.caName]}}
                />
                <HomeHeader 
                    names={names} 
                />
                <nav className='iconBar fullWidthNav fullWidth'>
                    {[
                        buttons.appraisal,
                        !isSubarea && buttons.management,
                    ]}
                </nav>
                <Description 
                    names={names}
                />
                <nav className='iconBar fullWidthNav fullWidth'>
                    {[
                        buttons.subareas,
                        buttons.designations
                    ]}
                </nav>
            </section>
        )
    } else {
        return (
            <section id='home'>
                <HomeHeader 
                    names={names} 
                />
                <nav className='iconBar fullWidthNav fullWidth'>
                    {[
                        buttons.appraisal,
                        !isSubarea && buttons.management,
                        buttons.subareas,
                        buttons.designations
                    ]}
                </nav>
            </section>
        )
    }
}