const findUpperIndex = (text, characterIndex, numOfWords) => {
    let upperIndex = characterIndex;
    const maxIndex = text.length-1;
    if (upperIndex !== maxIndex) {
        let numOfSpaces = -1;
        while (numOfSpaces < numOfWords && upperIndex !== maxIndex) {
            upperIndex ++
            let nextCharacter = text[upperIndex+1];
            if (nextCharacter === ' ') numOfSpaces ++;
        }
    }
    return upperIndex;
}

export default findUpperIndex;