import showSubareaFills from './showSubareaFills';

const caNames = [
    'Bloomsbury',
    'Charlotte Street',
    'Denmark Street',
    'Fitzroy Square',
    'Hanway Street',
    'Kingsway',
    'Seven Dials'
]

const resetSubareaFills = map => {
    caNames.forEach(caName => {
        showSubareaFills(map, caName, false);
    })
}

export default resetSubareaFills;