import fly from './fly';

const viewports = {
    "Euston": [-0.1328, 51.5267, 16.10],
    "Gordon Square": [-0.1312, 51.5248, 16.10],
    "Tottenham Court Road": [-0.1340, 51.5216, 15.70],
    "Knowledge Quarter": [-0.1301, 51.5221, 15.21],
    "Bedford Estate": [-0.1321, 51.5205, 15.56],
    "Central Bloomsbury": [-0.1264, 51.5228, 14.95],
    "Museum Village": [-0.1256, 51.5178, 16.40],
    "New Oxford Street": [-0.1264, 51.5177, 15.72],
    "Doughty Estate": [-0.1161, 51.5219, 15.54],
    "Holborn": [-0.1204, 51.5215, 15.54],
    "Foundling Estate": [-0.1209, 51.5255, 15.51],
    "King's Cross": [-0.1256, 51.5271, 15.51],
    "Calthorpe Estate": [-0.1167, 51.5270, 15.51],
    "Inns of Court": [-0.1158, 51.5183, 15.27],

    "Denmark Street": [-0.1291, 51.5151, 17.28],
    "St Giles Church": [-0.1283, 51.5150, 17.49],
    "Centre Point": [-0.1293, 51.5162, 17.49],

    "The Seven Dials": [-0.1264, 51.5143, 16.55],
    "Great Queen Street": [-0.1212, 51.5155, 17.09],
    "Macklin Street": [-0.1225, 51.5160, 16.86]

}

const flyToSubarea = (map, subareaName) => {
    fly(map, viewports[subareaName])
}

export default flyToSubarea;