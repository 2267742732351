import React, { useContext } from 'react';
import hasSubareas from './hasSubareas.js';
import DirectoryContext from '../../../../Contexts/Directory/DirectoryContext';

const SubareaButton = () => {
    const {directory, changeDirectory} = useContext(DirectoryContext);
    const [caName, displaying] = directory;
    if (!hasSubareas(caName)) return null;

    const subareasShowing = displaying==='subareas';
    const handleClick = () => {
        const newDirectory = subareasShowing ? [caName] : [caName, 'subareas'];
        changeDirectory(newDirectory);
    }
    const icon = <img className='icon' src='/icons/subareas.svg' alt=''/>
    return (
        <button className='iconButton' onClick={handleClick} alt=''>
            {icon}
            <span className='iconLabel'>{subareasShowing ? 'Hide' : 'Subareas'}</span>
        </button>
    )
}

export default SubareaButton;