import { addGeocoder } from './geocoder/addGeocoder';
import { makeMap } from './creation/makeMap';
import { addPlanningApplications } from './apiCalls/addPlanningApplications/addPlanningApplications';

export const initialiseMap = async ({viewportState, directoryState, planningApplicationsState}) => {
    const {setPlanningApplications} = planningApplicationsState;
    const {viewport} = viewportState;

    const Map = makeMap(viewport.value);

    addPlanningApplications(Map)
    .then(() => {
        setPlanningApplications({loaded: true})
    })

    addGeocoder(Map);

    return Map;
}