import fly from './fly';

const designationAreaViewports = {
    "Bloomsbury": {
        "1968(1)": [-0.1292, 51.5227, 14.64],
        "1968(2)": [-0.1234, 51.5291, 16.98],
        "1968(3)": [-0.1169, 51.5164, 16.30],
        "1973(1)": [-0.1287, 51.5176, 16.41],
        "1973(2)": [-0.1159, 51.5251, 16.98],
        "1973(3)": [-0.1180, 51.5284, 16.73],
        "1974(1)": [-0.1228, 51.5214, 16.50],
        "1974(2)": [-0.1124, 51.5157, 17.57],
        "1982(1)": [-0.1303, 51.5169, 17.60],
        "1984(1)": [-0.1344, 51.5268, 16.27],
        "1984(2)": [-0.1345, 51.5228, 16.82],
        "1984(3)": [-0.1136, 51.5186, 17.40],
        "1984(4)": [-0.1254, 51.5251, 18.06],
        "1984(5)": [-0.1256, 51.5268, 17.01],
        "1984(6)": [-0.1171, 51.5259, 16.91],
        "1984(7)": [-0.1154, 51.5265, 17.15],
        "1991(1)": [-0.1163, 51.5295, 17.97],
        "1991(2)": [-0.1214, 51.5225, 16.93],
        "1991(3)": [-0.1157, 51.5186, 17.56],
        "1991(4)": [-0.1193, 51.5175, 17.35],
        "1991(5)": [-0.1241, 51.5175, 17.20],
        "1991(6)": [-0.1290, 51.5169, 17.57],
        "1992(1)": [-0.1186, 51.5194, 16.89],
        "1999(1)": [-0.1149, 51.5223, 17.25],
        "1999(2)": [-0.1245, 51.5244, 16.96],
        "2011(1)": [-0.1355, 51.5255, 17.50],
        "2011(2)": [-0.1350, 51.5220, 16.18],
        "2011(3)": [-0.1268, 51.5236, 17.52],
        "2011(4)": [-0.1271, 51.5244, 17.52],
        "2011(5)": [-0.1238, 51.5211, 16.53],
        "2011(6)": [-0.1213, 51.5207, 17.80],
        "2011(7)": [-0.1179, 51.5185, 16.69],
        "2011(8)": [-0.1130, 51.5161, 18.31],
        "2011(9)": [-0.1158, 51.5235, 17.08],
        "2011(10)": [-0.1160, 51.5259, 17.81],
        "2011(11)": [-0.1143, 51.5260, 19.17],
        "2011(12)": [-0.1167, 51.5275, 18.81],
        "2011(13)": [-0.1179, 51.5269, 18.16],
        "2011(14)": [-0.1161, 51.5284, 16.97],
        "2011(15)": [-0.1200, 51.5283, 17.59],
        "2011(16)": [-0.1256, 51.5278, 16.67],
        "2011(17)": [-0.1246, 51.5263, 16.91]
    },
    "Charlotte Street": {
        "1968(1)": [-0.1336, 51.5192, 18.04],
        "1973(1)": [-0.1360, 51.5193, 16.69],
        "1981(1)": [-0.1373, 51.5193, 18.35],
        "1991(1)": [-0.1380, 51.5203, 17.75],
        "1995(1)": [-0.1344, 51.5202, 18.63],
        "1999(1)": [-0.1373, 51.5193, 18.35],
        "1999(2)": [-0.1369, 51.5206, 18.74],
        "1999(3)": [-0.1357, 51.5209, 17.48],
        "1999(4)": [-0.1342, 51.5194, 17.64],
        "1999(5)": [-0.1338, 51.5188, 18.42],

    },
    "Denmark Street": {
        "1984(1)": [-0.1294, 51.5150, 17.49],
        "1991(1)": [-0.1300, 51.5157, 18.27],
        "1998(1)": [-0.1300, 51.5157, 18.27]
    },
    "Fitzroy Square": {
        "1968(1)": [-0.1410, 51.5231, 17.13],
        "1980(1)": [-0.1409, 51.5233, 16.43],
        "1985(1)": [-0.1383, 51.5242, 17.92]
    },
    "Hanway Street": {
        "1989(1)": [-0.1321, 51.5172, 17.85],
    },
    "Kingsway": {
        "1981(1)": [-0.1209, 51.5164, 16.62],
    },
    "Seven Dials": {
        "1971(1)": [-0.1215, 51.5153, 17.41],
        "1974(1)": [-0.1280, 51.5143, 16.64],
        "1991(1)": [-0.1247, 51.5147, 17.80],
        "1991(2)": [-0.1291, 51.5137, 18.58],
        "1998(1)": [-0.1229, 51.5159, 17.19],
    }
}

const flyToDesignationArea = (map, caName, designationArea) => {
    if (!designationAreaViewports[caName][designationArea]) return;
    const viewport = designationAreaViewports[caName][designationArea]
    fly(map, viewport);
}

export default flyToDesignationArea; 