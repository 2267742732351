import React, { useState, useContext } from 'react';
import ContentsTab from './ContentsTab/ContentsTab.jsx';
import ReadTab from './ReadTab/ReadTab.jsx';
import ImageBanner from '../ImageBanner/ImageBanner.jsx';
import DocumentNavBar from './DocumentNavBar/DocumentNavBar.jsx';
import newLocation from './newLocation/newLocation';
import getMapData from './processData/map/getMapData';
import DocumentHeader from './DocumentHeader/DocumentHeader.jsx';
import TextHeader from './TextHeader/TextHeader.jsx';
import CopyButton from './CopyButton/CopyButton.jsx';
import Location from '../../../Contexts/Location/Location';
import getTitle from './processData/titles/getTitle';
import getSubtitle from './processData/subtitles/getSubtitle';
import getDocumentMap from './processData/documentMap/getDocumentMap';
import getImageObject from './processData/images/getImageObject';
import getImageFolderPath from './processData/images/getImageFolderPath';
import DirectoryContext from '../../../Contexts/Directory/DirectoryContext';
import getNames from '../../../Components/Map/directory/getNames';
import './Document.css';
import DeviceTypeContext from '../../../Contexts/DeviceType/DeviceTypeContext.jsx';

export default ({data, returnHome, setLayers, setViewport}) => {
    const deviceType = useContext(DeviceTypeContext);
    const [openTab, changeTab] = useState('Contents');
    const {directory} = useContext(DirectoryContext);
    const names = getNames(directory);
    const [location, setLocation] = useState({
        titleIndex: 0,
        subtitleIndex: 0,
        paragraphIndex: 0,
        elementIndex: 0
    })
    const documentMap = getDocumentMap(data.document);
    const updateMap = (viewport, layers) => {
        setViewport({
            value: viewport,
            fly: true
        });
        setLayers({
            all: data.layers['all'],
            showing: layers
        })
    }
    const updateMapByLocation = location => {
        const {viewport, layers} = getMapData(data, location);
        updateMap(viewport, layers);
    }
    const changeParagraph = next => {
        const locationToSet = newLocation(documentMap, location, next);
        updateMapByLocation(locationToSet);
        setLocation(locationToSet);
        document.getElementById('paragraphs').scrollTop = 0;
    }
    const updateLocation = location => {
        setLocation(location);
        updateMapByLocation(location);
    }
    const decideTitles = () => {
        if (openTab==='Contents') {
            const {caName, subareaName} = names;
            return {
                title: `${subareaName || caName} ${data.document.meta.name}`,
                subtitle: `Contents`
            }
        } else {
            return {
                title: getTitle(data.document, location).titleName,
                subtitle: getSubtitle(data.document, location).subtitleName
            }
        }
    }
    const getImageData = () => {
        const imageObject = getImageObject(data, location);
        const folderPath = getImageFolderPath(directory);
        return {
            url: folderPath + imageObject.name,
            caption: imageObject.caption
        }
    }
    if (deviceType === 'desktop') {
        return (
            <>
                <Location location={location} setLocation={updateLocation}>
                    <ImageBanner
                        imageData={getImageData()}
                    />
                    <DocumentHeader openTab={openTab}>
                        <TextHeader titles={decideTitles(openTab)}/>
                        <CopyButton data={data} names={names}/>
                    </DocumentHeader>
                    <DocumentNavBar
                        openTab={openTab} 
                        handleTabClick={changeTab} 
                        returnHome={returnHome} 
                    />
                    <ContentsTab 
                        openTab={openTab}
                        openDocument={data.document}
                        changeTab={changeTab}
                    />
                    <ReadTab
                        openTab={openTab}
                        data={data}
                        names={names}
                        changeParagraph={changeParagraph}
                    />
                </Location>
                <div id='copyButtonDiv' style={{height: 0}}>
                </div>
            </>
        )
    } else if (deviceType === 'mobile') {
        return (
            <>
                <Location location={location} setLocation={updateLocation}>
                    <DocumentNavBar
                        openTab={openTab} 
                        handleTabClick={changeTab} 
                        returnHome={returnHome} 
                    />
                    <ContentsTab 
                        openTab={openTab}
                        openDocument={data.document}
                        changeTab={changeTab}
                    />
                    <ReadTab
                        openTab={openTab}
                        data={data}
                        names={names}
                        changeParagraph={changeParagraph}
                    />
                </Location>
                <div id='copyButtonDiv' style={{height: 0}}>
                </div>
            </>
        )
    } else {
        return (
            <>
                <Location location={location} setLocation={updateLocation}>
                    <ImageBanner
                        imageData={getImageData()}
                    />
                    <DocumentNavBar
                        openTab={openTab} 
                        handleTabClick={changeTab} 
                        returnHome={returnHome} 
                    />
                    <ContentsTab 
                        openTab={openTab}
                        openDocument={data.document}
                        changeTab={changeTab}
                    />
                    <ReadTab
                        openTab={openTab}
                        data={data}
                        names={names}
                        changeParagraph={changeParagraph}
                    />
                </Location>
                <div id='copyButtonDiv' style={{height: 0}}>
                </div>
            </>
        )
    }
    
}