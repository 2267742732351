import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';

export const addGeocoder = (map) => {
    const geocoder = new MapboxGeocoder({ 
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: {
            color: 'gray'
        }
    });

    geocoder.setProximity({
        latitude: 51.5217,
        longtiude: -0.1261
    })

    geocoder.setBbox([-0.1421, 51.5072, 0.1109, 51.5323])

    map.addControl(geocoder);
    document.querySelector('.mapboxgl-ctrl-geocoder--input').placeholder = 'Postcode'
}