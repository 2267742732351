import React from 'react';
import './Heritage.css';
import Main from './Main/Main';
import Directory from '../../Contexts/Directory/Directory';
import DeviceType from '../../Contexts/DeviceType/DeviceType';

export default ({viewportState, layersState, directoryState, deviceType}) => {
    return (
        <div id='heritage' className={`shadow ${deviceType}`}>
            <Directory directory={directoryState.directory} changeDirectory={directoryState.setDirectory}>
                <DeviceType deviceType={deviceType}>
                    <Main
                        setViewport={viewportState.setViewport}
                        setLayers={layersState.setLayers}
                    />
                </DeviceType>
            </Directory>
        </div> 
    )
}