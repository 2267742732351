const deviceMaxWidths = {
    mobile: 500,
    tablet: 900
}

export const getDeviceType = () => {
    const windowWidth = window.innerWidth;
    const devices = Object.keys(deviceMaxWidths);
    let deviceType;
    devices.forEach(device => {
        if (windowWidth < deviceMaxWidths[device] && !deviceType) {
            deviceType = device;
        }
    })
    if (!deviceType) deviceType = 'desktop';
    return deviceType;
}