import getDocumentMap from '../../../processData/documentMap/getDocumentMap';

const isEndOfDocument = ({titleIndex, subtitleIndex, paragraphIndex}, document) => {
    const documentMap = getDocumentMap(document);
    const maxTitleIndex = documentMap.length-1;
    const maxSubtitleIndex = documentMap[maxTitleIndex].length-1;
    const maxParagraphIndex = documentMap[maxTitleIndex][maxSubtitleIndex]-1;
    return (titleIndex===maxTitleIndex && maxSubtitleIndex===subtitleIndex && maxParagraphIndex===paragraphIndex);
}

export default isEndOfDocument;