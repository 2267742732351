const subareaLayers = {
    'Bloomsbury': 'bloomsbury-subareas',
    'Denmark Street': 'denmark-street-subareas',
    'Seven Dials': 'seven-dials-subareas'
}

const showSubareaFills = (map, caName, show) => {
    if (!subareaLayers[caName]) return;
    map.setPaintProperty(subareaLayers[caName], "fill-opacity", show ? 1: 0);
}

export default showSubareaFills;