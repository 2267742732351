import React from 'react';
import './TextHeader.css';

const TextHeader = ({titles}) => {
    const {title, subtitle} = titles;
    return (
        <div className='headerText'>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
        </div>
    )
}

export default TextHeader;
