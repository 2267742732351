const layers = {
    'conservationArea': [
        'bloomsbury', 
        'charlotte-street', 
        'denmark-street', 
        'fitzroy-square', 
        'hanway-street', 
        'kingsway', 
        'seven-dials'
    ],
    'subarea': [
        'bloomsbury-subareas',
        'denmark-street-subareas',
        'seven-dials-subareas'
    ],
    'designationArea': [
        'bloomsbury-designations', 
        'charlotte-street-designations', 
        'denmark-street-designations', 
        'fitzroy-square-designations', 
        'hanway-street-designations', 
        'kingsway-designations', 
        'seven-dials-designations'
    ],
    'planningApplications': [
        'determinedPastThreeMonths'
    ]
}

const informationCallbacks = {
    'conservationArea': (conservationAreas) => {
        const clickedConservationArea = conservationAreas[0];
        return [clickedConservationArea.properties.Name];
    },
    'subarea': (subareas) => {
        const clickedSubarea = subareas[0];
        const properties = clickedSubarea.properties;
        const {CA, Name} = properties;
        const subareaNumber = properties["Sub Area"];
        return [{CA, Name, subareaNumber}]
    },
    'designationArea': (designationAreas) => {
        const clickedDesignationArea = designationAreas[0];
        const {Conservation_Area, Designated, Year, areaId} = clickedDesignationArea.properties;
        return [{Conservation_Area, Designated, Year, areaId}];
    },
    'planningApplications': (planningApplications) => {
        return planningApplications;
    }
}

const getClickedFeatures = (map, clickEvent, layerType) => {
    //always returns an ARRAY
    const validLayerTypes = Object.keys(layers);
    if (!validLayerTypes.includes(layerType)) return;

    const layerNames = layers[layerType];

    const features = map.queryRenderedFeatures(clickEvent.point, {
        layers: layerNames
    })
    if (features.length) {
        const getUsefulInformation = informationCallbacks[layerType];
        return getUsefulInformation(features);
    } else {
        return [];
    }
}

export default getClickedFeatures;