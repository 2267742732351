import searchSubtitles from './searchSubtitles';
import searchParagraphs from './searchParagraphs';

const search = (document, term) => {
    const searchResult = {
        subtitles: searchSubtitles(document, term),
        paragraphs: searchParagraphs(document, term)
    }
    return searchResult;
}

export default search;