import React from 'react';
import { clickHandler } from './eventListeners/click/click';
import { mousemoveHandler } from './eventListeners/mousemove/mousemove';
import { moveHandler } from './eventListeners/move/move';
import { initialiseMap } from './initialiseMap/initialiseMap';
import { updateMap } from './updateMap/updateMap';
import { updateClusterMarkers } from './initialiseMap/apiCalls/updateClusterMarkers/updateClusterMarkers';

class Map extends React.Component {
    async componentDidMount() {
        this.Map = await initialiseMap(this.props);

        //these have to be set in a react component, else props don't update with app state change
        this.Map.on('mousemove', event => {
            mousemoveHandler(this.Map, event, this.props.planningApplicationsState);
        })
        this.Map.on('move', event => {
            moveHandler(this.Map, event, this.props.viewportState);
        })
        this.Map.on('click', event => {
            clickHandler(this.Map, event, this.props.directoryState, this.props.planningApplicationsState)
        })
        this.Map.on('data', event => {
            updateClusterMarkers(this.Map);
        })
        this.Map.on('moveend', event => {
            updateClusterMarkers(this.Map);
        })
    }

    componentDidUpdate(prevProps) {
        updateMap(this.Map, {prevProps, newProps: this.props});
    }

    render() {
        return (
            <div id="mapContainer">
                {/*map goes here*/}
            </div>
        )
    }
}

export default Map;