import React from 'react';
import './HomeHeader.css';

//expects prop caName and subareaName

const caSpans = {
    'Bloomsbury': 'Significance: Exceptional',
    'Charlotte Street': 'Significance: High',
    'Denmark Street': 'Significance: Medium',
    'Fitzroy Square': 'Significance: High',
    'Hanway Street': 'Significance: Medium',
    'Kingsway': 'Significance: High',
    'Seven Dials': 'Significance: High',
}

const subareaSpans = {
    'Euston': 'Subarea 1, Bloomsbury',
    'Gordon Square': 'Subarea 2, Bloomsbury',
    'Knowledge Quarter': 'Subarea 3, Bloomsbury',
    'Tottenham Court Road': 'Subarea 4, Bloomsbury',
    'Bedford Estate': 'Subarea 5, Bloomsbury',
    'Central Bloomsbury': 'Subarea 6, Bloomsbury',
    'Museum Village': 'Subarea 7, Bloomsbury',
    'New Oxford Street': 'Subarea 8, Bloomsbury',
    'Inns of Court': 'Subarea 9, Bloomsbury',
    'Doughty Estate': 'Subarea 10, Bloomsbury',
    'Holborn': 'Subarea 11, Bloomsbury',
    'Foundling Estate': 'Subarea 12, Bloomsbury',
    "King's Cross": 'Subarea 13, Bloomsbury',
    'Calthorpe Estate': 'Subarea 14, Bloomsbury',

    'The Seven Dials': 'Subarea 1, Seven Dials',
    'Great Queen Street': 'Subarea 2, Seven Dials',
    'Macklin Street': 'Subarea 3, Seven Dials',

    'Denmark Street': 'Subarea 1, Denmark Street',
    'St Giles Church': 'Subarea 2, Denmark Street',
    'Centre Point': 'Subarea 3, Denmark Street'
}

export default ({names}) => {
    const heading = names.subareaName || names.caName;
    const subheading = names.subareaName ? subareaSpans[names.subareaName] : caSpans[names.caName];
    return (
        <header id='homeHeader' className='padded fullWidth header'>
            <h1>{heading}</h1>
            <p>{subheading}</p>
        </header>
    )
}