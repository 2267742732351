import React from 'react';
import LocationContext from './LocationContext'

const Location = ({children, location, setLocation}) => {
    return (
        <LocationContext.Provider value={{location, setLocation}}>
            {children}
        </LocationContext.Provider>
    )
}

export default Location;