const getPreviousLocation = (documentOrder, {titleIndex, subtitleIndex, paragraphIndex}) => {
    if (paragraphIndex > 0) {
        return ({
            titleIndex: titleIndex,
            subtitleIndex: subtitleIndex,
            paragraphIndex: paragraphIndex - 1,
            elementIndex: 0
        })
    } else {  
        if (subtitleIndex > 0) {
            return ({
                titleIndex: titleIndex,
                subtitleIndex: subtitleIndex - 1,
                paragraphIndex: documentOrder[titleIndex][subtitleIndex-1]-1,
                elementIndex: 0

            })
        } else {
            return ({
                titleIndex: titleIndex - 1,
                subtitleIndex: documentOrder[titleIndex-1].length-1,
                paragraphIndex: documentOrder[titleIndex-1][documentOrder[titleIndex-1].length-1]-1,
                elementIndex: 0
            })
        }
    }     
}

export default getPreviousLocation;