const caNames = {
    'Bloomsbury': `bloomsbury`,
    'Charlotte Street': `charlotteStreet`,
    'Denmark Street': `denmarkStreet`,
    'Fitzroy Square': `fitzroySquare`,
    'Hanway Street': `hanwayStreet`,
    'Kingsway': `kingsway`,
    'Seven Dials': `sevenDials`
}

const subareaNames = {
    'Euston': `euston`,
    'Gordon Square': `gordonSquare`,
    'Knowledge Quarter': `knowledgeQuarter`,
    'Tottenham Court Road': `tottenhamCourtRoad`,
    'Bedford Estate': `bedfordEstate`,
    'Central Bloomsbury': `centralBloomsbury`,
    'Museum Village': `museumVillage`,
    'New Oxford Street': `newOxfordStreet`,
    'Inns of Court': `innsOfCourt`,
    'Doughty Estate': `doughtyEstate`,
    'Holborn': `holborn`,
    'Foundling Estate': `foundlingEstate`,
    "King's Cross": `kingsCross`,
    'Calthorpe Estate': `calthorpeEstate`,

    'The Seven Dials': `theSevenDials`,
    'Great Queen Street': `greatQueenStreet`,
    'Macklin Street': `macklinStreet`,

    'Denmark Street': `denmarkStreet`,
    'St Giles Church': `stGilesChurch`,
    'Centre Point': `centrePoint`
}

const getFolderPath = (directory, documentName) => {
    if (directory[1]==='subareas' && directory[2]) {
        return `${caNames[directory[0]]}/subareas/${subareaNames[directory[2]]}`
    } else {
        return `${caNames[directory[0]]}/${documentName}`
    }
}

export default getFolderPath;