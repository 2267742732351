import React from 'react';
import Subtitle from './Subtitle.jsx';
import getSubtitles from '../../../processData/subtitles/getSubtitles';

const SubtitleList = ({titleIndex, openDocument, startReading}) => {
    const subtitles = getSubtitles(openDocument, {titleIndex: titleIndex})
    .map((subtitle, subtitleIndex) => {
        return (
            <Subtitle
                key={subtitleIndex}
                subtitleName={subtitle.subtitleName}
                titleIndex={titleIndex}
                subtitleIndex={subtitleIndex}
                startReading={startReading}
            />
        )
    })

    return <ul>{subtitles}</ul>
}

export default SubtitleList;