import showAllCaFills from '../areas/showAllCaFills';
import resetDesignations from '../designations/resetDesignations';
import resetCaNames from '../names/resetCaNames';
import resetSubareaNames from '../names/resetSubareaNames';
import resetSubareaFills from '../subareas/resetSubareaFills';
import resetDates from '../designations/resetDates';

const resetLayers = map => {
    //note the order is important for animation purposes 
    //we wouldn't want the subareas to disappear yet the names still be there for a second or so as it transitions
    resetDates(map);
    resetDesignations(map);
    resetSubareaNames(map);
    resetSubareaFills(map);
    showAllCaFills(map, true);
    resetCaNames(map);
}

export default resetLayers;