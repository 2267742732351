import React, { useContext } from 'react';
import LocationContext from '../../../../../../Contexts/Location/LocationContext';

const Subtitle = ({subtitleName, subtitleIndex, titleIndex, startReading}) => {
    const {location} = useContext(LocationContext);
    const handleClick = () => {
        startReading({
            titleIndex: titleIndex,
            subtitleIndex: subtitleIndex,
            paragraphIndex: 0,
            elementIndex: 0
        });
    }
    const className = subtitleIndex===location.subtitleIndex ? 'clickedSubtitle' : '';

    return (
        <li>
            <h3 onClick={handleClick} className={className}>{subtitleName}</h3>
        </li>
    )
}

export default Subtitle;