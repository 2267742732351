import React from 'react';
import makeTextSnippet from './helpers/makeTextSnippet';
import getParagraph from '../../../../processData/paragraphs/getParagraph';
import getTitle from '../../../../processData/titles/getTitle';
import getSubtitle from '../../../../processData/subtitles/getSubtitle';
import './ParagraphResult.css';

const ParagraphResult = ({openDocument, location, searchTerm, startReading}) => {
    //paragraph will be a React JSX object, not a string
    const paragraph = getParagraph(openDocument, location);
    const {text, reference} = paragraph;
    const titleName = getTitle(openDocument, location).titleName;
    const subtitleName = getSubtitle(openDocument, location).subtitleName;

    const searchTermIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase())
    const paragraphSnippetHTML = makeTextSnippet(text, searchTermIndex, 10, searchTerm);

    const handleClick = () => {
        startReading(location);
    }

    return (
        <div className='paragraphResult' onClick={handleClick}>
            <header>
                <h1>{titleName}</h1>
                <h2>{subtitleName}</h2>
                <h3>Paragraph {reference}</h3>
            </header>
            <p className='paragraphResultExtract' dangerouslySetInnerHTML={{__html: paragraphSnippetHTML}}></p>
        </div>
    )
}

export default ParagraphResult;