import { updateClusterMarkers } from "../../initialiseMap/apiCalls/updateClusterMarkers/updateClusterMarkers";

const waitMillisecond = () => {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, 1);
    })
}

const removeMarkers = async (map) => {
    let markers = updateClusterMarkers(map);
    let markersStillOnScreen = !!Object.keys(markers).length;
    if (markersStillOnScreen) {
        waitMillisecond()
        .then(() => removeMarkers(map));
    }
}

export const updateVisibility = (map, prevVisibility, newVisibility) => {
    //first check if change at all
    const hasChanged = JSON.stringify(prevVisibility) !== JSON.stringify(newVisibility);
    if (!hasChanged) return;

    //check if global change
    if (prevVisibility.global !== newVisibility.global) {
        map.setLayoutProperty('determinedPastThreeMonths', "visibility", newVisibility.global ? "visible" : "none");
        map.setLayoutProperty('planningApplicationsClusters', "visibility", newVisibility.global ? "visible" : "none");
        if (newVisibility.global===false) {
            //remove markers asynchronously, when map is ready
            removeMarkers(map)
        }
    }
    return;
}