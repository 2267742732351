import showCaFill from './showCaFill'

const caNames = [
    'Bloomsbury',
    'Charlotte Street',
    'Denmark Street',
    'Fitzroy Square',
    'Hanway Street',
    'Kingsway',
    'Seven Dials'
]

const showAllCaFills = (map, show) => {
    caNames.forEach(caName => showCaFill(map, caName, show))
}

export default showAllCaFills;