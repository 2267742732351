const invisiblePlanningApplicationsLayer = {
    'id': 'determinedPastThreeMonths',
    'type': 'circle',
    'source': 'determinedPastThreeMonths',
    'layout': {
        'visibility': 'none'
    },
    'paint': {
        'circle-color': "hsla(0,0,0,0)",
        'circle-radius': 10
    }
}

const approvedColor = 'green';
const warningColor = 'black';
const rejectedColor = 'red';
const openColor = 'orange';

const clusterCircleRadius = 10;
const singleCircleRadius = 5;

const singleCircleColor = "hsla(0%, 0%, 0, 0.7)";

const singleCircleStrokeWidth = 2;

const clusterPlanningApplicationsLayer = {
    'id': 'planningApplicationsClusters',
    'type': 'circle',
    'source': 'planningApplicationsClusters',
    'layout': {
        'visibility': 'none'
    },
    'paint': {
        'circle-stroke-color': [
            'match',
            ['get', 'decision_type'],
            'Granted',
            approvedColor,
            'No Objection to Emergency Works (CA)',
            approvedColor,
            'No Objection to Works to Tree(s) in CA',
            approvedColor,
            'Granted Subject to a Section 106 Legal Agreement',
            approvedColor,
            'Prior Approval Required - Approval Given',
            approvedColor,
            'Refused and Warning of Enforcement Action to be Taken',
            warningColor,
            'Withdrawn Decision',
            rejectedColor,
            'Refused',
            rejectedColor,
            'Prior Approval Required - Approval Refused',
            rejectedColor,
            openColor,
        ],
        'circle-color': [
            'case',
            ['==', ['get', 'cluster'], true],
            "hsla(0,0,0,0)",
            singleCircleColor
            
        ],
        'circle-radius': [
            'case',
            ['==', ['get', 'cluster'], true],
            clusterCircleRadius,
            singleCircleRadius
        ],
        'circle-stroke-width': [
            'case',
            ['==', ['get', 'cluster'], true],
            0,
            singleCircleStrokeWidth
        ]
    }
}

const layerIsBehind = 'pedestrian-names';


export const addLayersToMap = (map) => {
    map.addLayer(invisiblePlanningApplicationsLayer, layerIsBehind);
    map.addLayer(clusterPlanningApplicationsLayer, layerIsBehind);
}