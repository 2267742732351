import getParagraphRef from "../paragraphs/getParagraphRef"

const getImageObject = ({document, images}, location) => {
    const paragraphRef = getParagraphRef(document, location);
    if (!images[paragraphRef]) {
        return {
            "name": "default.JPG",
            "caption": "This paragraph does not yet have an image"
        }
    } else {
        return images[paragraphRef]["default"] ? images[paragraphRef]["default"] : images[paragraphRef]; 
    }
}

export default getImageObject;