import handleMapClick from "../helpers/handleMapClick";

export const clickHandler = (map, event, {directory, setDirectory}, {planningApplications, setPlanningApplications}) => {
    //handleMapClick also executes some code, but returns the (possibly) new directory
    const {directory: newDirectory, planningApplication: newPlanningApplications} = handleMapClick(map, directory, event);
    setDirectory(newDirectory);
    const oldSelectedApplications = planningApplications.selectedApplications;
    oldSelectedApplications.forEach(feature => {
        map.setFeatureState(feature, {
            focused: false
        })
    })
    setPlanningApplications({selectedApplications: newPlanningApplications});
}