import React, { useContext } from 'react';
import SubtitleList from './Subtitles/SubtitleList.jsx';
import LocationContext from '../../../../../Contexts/Location/LocationContext';
import getTitle from '../../processData/titles/getTitle';
import './Title.css';

const Title = ({titleIndex, openDocument, startReading}) => {
    const {location} = useContext(LocationContext);
    const handleClick = () => {
        startReading({
            titleIndex: titleIndex,
            subtitleIndex: 0,
            paragraphIndex: 0,
            elementIndex: 0
        });
    }

    const {titleName} = getTitle(openDocument, {titleIndex});
    const isCurrentTitle = titleIndex===location.titleIndex;
    return (
        <li id={isCurrentTitle ? 'selectedTitleElement': ''} className='titleSection'>
            <h2 onClick={handleClick} className={isCurrentTitle ? 'clickedTitle': ''}>{titleName}</h2>
            <SubtitleList
                openDocument={openDocument} 
                titleIndex={titleIndex} 
                startReading={startReading}
            />
        </li>
    )
}

export default Title;