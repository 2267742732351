const subtractNumOfMonths = (date, numOfMonths) => {
    const dateToMutate = new Date(date);
    dateToMutate.setMonth(dateToMutate.getMonth() - numOfMonths);
    //above returns ISO timestamp
    const dateNumMonthsAgo = new Date(dateToMutate);
    return dateNumMonthsAgo;
}

const convertDateToPassableString = (date) => {
    const dateISOFormat = date.toISOString();
    const dateCorrectFormat = dateISOFormat.slice(0, dateISOFormat.length-1)
    return dateCorrectFormat;
}

export async function makeApiRequest() {
    //load relevant dates
    const todayDate = new Date();
    const dateThreeMonthsAgo = subtractNumOfMonths(todayDate, 3);
    //convert to correct format
    const todayDateToPass = convertDateToPassableString(todayDate);
    const dateThreeMonthsAgoToPass = convertDateToPassableString(dateThreeMonthsAgo);

    const areaOfInterestPolygon = [
    '-0.1290894 51.5338165',
    '-0.1261282 51.5345907',
    '-0.1217079 51.5341903',
    '-0.1215792 51.5317876',
    '-0.1193476 51.5317609',
    '-0.1141548 51.5303460',
    '-0.1138544 51.5281567',
    '-0.1108932 51.5260207',
    '-0.1101208 51.5250061',
    '-0.1123524 51.5232438',
    '-0.1120520 51.5215082',
    '-0.1097345 51.5177429',
    '-0.1097775 51.5141376',
    '-0.1194334 51.5119476',
    '-0.1280594 51.5094636',
    '-0.1298189 51.5119743',
    '-0.1453114 51.5237512',
    '-0.1444960 51.5247391',
    '-0.1418352 51.5250862',
    '-0.1360846 51.5265547',
    '-0.1393890 51.5296251',
    '-0.1344538 51.5316007',
    '-0.1310205 51.5286907',
    '-0.1292181 51.5293581',
    '-0.1311922 51.5317075',
    '-0.1294756 51.5325885',
    '-0.1291752 51.5338165',
    ]

    //API requires polygon coordinates to 'join back up' at the end
    const polygonCoordinatesCorrectFormat = `${areaOfInterestPolygon.join(', ')}, ${areaOfInterestPolygon[0]}`;

    const polygonFunction = `'POLYGON((${polygonCoordinatesCorrectFormat}))'`
    const polygonQueryString = `within_polygon(location, ${polygonFunction})`

    const bloomsburyApplicationsURL = `https://opendata.camden.gov.uk/resource/2eiu-s2cw.geojson?$WHERE=${polygonQueryString}`;

    const queryStrings = {
        openApplication: `system_status=Registered`,
        unlimited: `$limit=300000`,
        determinedPastThreeMonths: `decision_date between '${dateThreeMonthsAgoToPass}' and '${todayDateToPass}'`,
    }

    const openApplicationsResponse = await fetch(`${bloomsburyApplicationsURL}&${queryStrings.openApplication}&${queryStrings.unlimited}`);
    const openApplications = await openApplicationsResponse.json();
    if (!openApplicationsResponse.ok) {
        throw new Error(`Error in calling API for 'Open Applications:' ${openApplications.message}`);
    }
    
    const determinedPastThreeMonthsResponse = await fetch(`${bloomsburyApplicationsURL}and ${queryStrings.determinedPastThreeMonths}&${queryStrings.unlimited}`);
    const determinedPastThreeMonths = await determinedPastThreeMonthsResponse.json();
    if (!determinedPastThreeMonthsResponse.ok) {
        throw new Error(`Error in calling API for 'Recent Applications': ${determinedPastThreeMonths.message}`);
    }

    const combinedFeatures = [...openApplications.features, ...determinedPastThreeMonths.features];
    const combinedFeatureCollection = {type: "FeatureCollection", features: combinedFeatures};

    return {
        openApplications,
        determinedPastThreeMonths,
        combinedApplications: combinedFeatureCollection
    }
}